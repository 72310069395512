<template>
    <div style="height: 100vh; contain: content; background-color: var(--v-greyBase-base) !important;">
        <div id="Tabs" class="tabsTop d-flex" v-if="$store.state.user.id == 69">
            <v-tabs v-model="selectedTabs" height="30px" :background-color="'var(--v-greyBase-base) !important'">
                <v-tab v-for="(tab, index) in tabs" :key="index" :href="'#tab-' + tab.name">
                    {{ tab.name }}
                </v-tab>
            </v-tabs>
        </div>
        <v-tabs-items v-model="selectedTabs" style="height: 100%">
            <v-tab-item value="tab-Containers">
        <!-- TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR  --  TOP-BAR -->
                <div id="parentContainer" style="display: flex; width: 100%; contain: content;">
                    <div style="width: 100%; display: grid">
                        <div class="secondRow">
                            <v-menu bottom offset-y :close-on-content-click="false">
                                <template v-slot:activator="{on, attrs}">
                                    <v-chip :color="selectedTemplate?.title ? 'var(--v-primary-darken2)' : ''" :outlined="selectedTemplate?.title ? false : true" small dense class="mr-2 px-2" v-bind="attrs" v-on="on" @click="getTemplates()"><v-icon class="chipIcon">view_carousel</v-icon>{{selectedTemplate ? selectedTemplate.title ?? 'Layout' : 'Layout'}}</v-chip>
                                </template>
                                <v-card  style="min-width: 300px; max-width: 400px; max-height: 60vh; overflow-y: auto; overflow-x: hidden; background: var(--v-greyRaised-lighten1) !important;">
                                    <v-card-text class="pt-0 px-2">
                                        <v-subheader style="height: 40px;" class="px-0">
                                            Loglive Templates
                                            <v-spacer></v-spacer>
                                            <v-menu bottom offset-y :close-on-content-click="false" v-if="selectedTemplate && selectedTemplate.type && (canUpdateOwnTemplate || canUpdateOrgTemplate) && ((selectedTemplate?.type == 'UserTemplate') || (selectedTemplate?.type == 'OrgTemplate' && ['@dotparse', '@loglive'].some(x => $store.state.user.emailAddress.includes(x))))"> 
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-chip outlined color="primary" small dense class="mr-1 px-2" v-bind="attrs" v-on="on"><v-icon class="chipIcon">save</v-icon>Update</v-chip>
                                                </template>
                                                <v-card style="background: var(--v-greyRaised-lighten1) !important; max-width: 400px; width: 400px;">
                                                    <v-toolbar flat dense height="35px">
                                                        <v-toolbar-title style="font-size: 16px; opacity: 0.7;">
                                                            Update Template
                                                        </v-toolbar-title>
                                                    </v-toolbar>
                                                    <v-card-text class="pt-0">
                                                        <div style="width: 100%;flex-direction: column;" class="d-flex justify-center align-center mb-3">
                                                            <span style="font-size: 12px;" class="mb-1"> Current Template</span>
                                                            <v-card elevation-12 style="width: 220px; aspect-ratio: 3/1; border-radius: 16px; background: var(--v-primary-darken2) !important;" class="d-flex align-center pa-1">
                                                                <v-card height="100%" style=" border-radius: 16px; aspect-ratio: 1/1; flex-direction: column; background: var(--v-greyRaised-lighten1) !important; opacity: 0.5;" class="mr-2 d-flex justify-center align-center">
                                                                    <v-icon>{{selectedTemplate.templateType == 'table' ? 'reorder' : selectedTemplate.templateType == 'dashboard' ? 'dashboard' : 'list' }}</v-icon>
                                                                    <span style="font-size: 10px;">{{selectedTemplate.templateType}}</span>
                                                                </v-card>   
                                                                <span style="text-align: center; font-size: 12px;" >{{ selectedTemplate.title }}</span>
                                                            </v-card>
                                                        </div>
                                                        <v-text-field v-model="selectedTemplate.title" outlined dense hide-details label="Title" placeholder="Exporter View" persistent-placeholder></v-text-field>
                                                        <v-subheader style="height: 30px;" class="px-0 mt-3">
                                                            Available To
                                                        </v-subheader>
                                                        <v-divider></v-divider>
                                                        <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;">
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Everyone')" :color="selectedTemplate.allowedParties.includes('Everyone') ? 'tertiary' : ''"  @click="templateTag('Everyone', null, 'selected')">Everyone</v-chip>
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Forwarder')" :color="selectedTemplate.allowedParties.includes('Forwarder') ? 'tertiary' : ''"  @click="templateTag('Forwarder', null, 'selected')">Forwarder</v-chip>
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Shipper')" :color="selectedTemplate.allowedParties.includes('Shipper') ? 'tertiary' : ''"  @click="templateTag('Shipper', null, 'selected')">Shipper</v-chip>
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Consignee')" :color="selectedTemplate.allowedParties.includes('Consignee') ? 'tertiary' : ''"  @click="templateTag('Consignee', null, 'selected')">Consignee</v-chip>
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Buyer')" :color="selectedTemplate.allowedParties.includes('Buyer') ? 'tertiary' : ''"  @click="templateTag('Buyer', null, 'selected')">Buyer</v-chip>
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('Transporter')" :color="selectedTemplate.allowedParties.includes('Transporter') ? 'tertiary' : ''"  @click="templateTag('Transporter', null, 'selected')">Transporter</v-chip>
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('On Behalf Shipper')" :color="selectedTemplate.allowedParties.includes('On Behalf Shipper') ? 'tertiary' : ''"  @click="templateTag('On Behalf Shipper', null, 'selected')">On Behalf Shipper</v-chip>
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('On Behalf Consignee')" :color="selectedTemplate.allowedParties.includes('On Behalf Consignee') ? 'tertiary' : ''"  @click="templateTag('On Behalf Consignee', null, 'selected')">On Behalf Consignee</v-chip>
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('On Behalf Forwarder')" :color="selectedTemplate.allowedParties.includes('On Behalf Forwarder') ? 'tertiary' : ''"  @click="templateTag('On Behalf Forwarder', null, 'selected')">On Behalf Forwarder</v-chip>
                                                            <v-chip class="mx-1 my-1" small :outlined="!selectedTemplate.allowedParties.includes('On Behalf Buyer')" :color="selectedTemplate.allowedParties.includes('On Behalf Buyer') ? 'tertiary' : ''"  @click="templateTag('On Behalf Buyer', null, 'selected')">On Behalf Buyer</v-chip>
                                                        </div>
                                                        <v-subheader style="height: 30px;" class="px-0 mt-3">
                                                            Tags
                                                        </v-subheader>
                                                        <v-divider></v-divider>
                                                        <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;">
                                                            <v-chip class="mx-1 my-1" :outlined="!selectedTemplate.tags.includes('Logistical Docs')" :color="selectedTemplate.tags.includes('Logistical Docs') ? 'greyBase' : ''" small @click="templateTag('Logistical Docs', 'tags', 'selected')">Logistical Docs</v-chip>
                                                            <v-chip class="mx-1 my-1" :outlined="!selectedTemplate.tags.includes('Finance')" :color="selectedTemplate.tags.includes('Finance') ? 'greyBase' : ''" small @click="templateTag('Finance', 'tags', 'selected')">Finance</v-chip>
                                                            <v-chip class="mx-1 my-1" :outlined="!selectedTemplate.tags.includes('Bookings')" :color="selectedTemplate.tags.includes('Bookings') ? 'greyBase' : ''" small @click="templateTag('Bookings', 'tags', 'selected')">Bookings</v-chip>
                                                            <v-chip class="mx-1 my-1" :outlined="!selectedTemplate.tags.includes('Clients')" :color="selectedTemplate.tags.includes('Clients') ? 'greyBase' : ''" small @click="templateTag('Clients', 'tags', 'selected')">Clients</v-chip>
                                                        </div>
                                                        <div class="mt-3 d-flex justify-center align-center">
                                                            <v-btn color="primary" :disabled="!selectedTemplate.title" @click="updateTemplate(selectedTemplate.type)" :loading="loadingTemplateSave">Update</v-btn>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-menu>
                                            <v-tooltip right transition="slide-x-transition" v-if="selectedTemplate?.title && ((selectedTemplate?.type == 'UserTemplate') || (selectedTemplate?.type == 'OrgTemplate' && ['@dotparse', '@loglive'].some(x => $store.state.user.emailAddress.includes(x))))">
                                                <template v-slot:activator="{on}">
                                                    <v-chip v-on="on" small  class="px-1" color="var(--v-redPop-darken1)" @click="deletedSelectedTemplate" v-if="selectedTemplate?.title"><v-icon small>delete</v-icon></v-chip>
                                                </template>
                                                Delete Current Template
                                            </v-tooltip>
                                        </v-subheader>
                                        <v-row no-gutters v-if="!loadingTemplates">
                                            <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center" style="height: 40px;" v-if="(!templates.length || templates.length == 0) && !['@dotparse', '@loglive'].some(x => $store.state.user.emailAddress.includes(x))" >
                                                No Available Templates
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6" lg="6" v-for="(template, index) in templates" :key="index" class="px-1 py-1" v-else>
                                                <v-hover v-slot="{ hover }">
                                                    <v-card @click="changeTemplate(template, index)" v-if="template && template.templateType" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center pa-1" style="cursor: pointer; border-radius: 16px; width: 100%; aspect-ratio: 3/1;" :style="selectedTemplate?.id == template.id ? {'background': 'var(--v-primary-darken2) !important'} : {'background': 'var(--v-greyRaised-lighten2) !important'}">
                                                        <v-card height="100%" style=" border-radius: 16px; aspect-ratio: 1/1; flex-direction: column; background: var(--v-greyRaised-lighten1) !important; opacity: 0.5;" class="mr-2 d-flex justify-center align-center">
                                                            <v-icon small>{{template.templateType == 'Table' ? 'reorder' : template.templateType == 'Dashboard' ? 'dashboard' : 'list' }}</v-icon>
                                                            <span style="font-size: 10px;">{{template.templateType}}</span>
                                                        </v-card>
                                                        <span style="text-align: center; font-size: 12px;" >{{ template.title }}</span>
                                                    </v-card>
                                                </v-hover>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex justify-center align-center px-1 py-1" v-if="['@dotparse', '@loglive'].some(x => $store.state.user.emailAddress.includes(x))">
                                                <v-menu bottom offset-y :close-on-content-click="false">
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-hover v-slot="{ hover }">
                                                            <v-card v-on="on" v-bind="attrs" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center pa-1" style="border: 1px dashed rgb(135, 135, 135); cursor: pointer; border-radius: 16px; width: 100%; aspect-ratio: 3/1; background: var(--v-greyRaised-lighten1) !important">
                                                                <v-card height="100%" style=" border-radius: 16px; aspect-ratio: 1/1; flex-direction: column; background: var(--v-greyRaised-lighten2) !important; opacity: 0.4;" class="mr-2 d-flex justify-center align-center">
                                                                    <v-icon>add_circle</v-icon>
                                                                </v-card>
                                                                <span style="text-align: center; font-size: 12px;" >Create Template</span>
                                                            </v-card>
                                                        </v-hover>
                                                    </template>
                                                    <v-card style="background: var(--v-greyRaised-lighten1) !important; max-width: 400px; width: 400px; ">
                                                        <v-toolbar flat dense height="35px">
                                                            <v-toolbar-title style="font-size: 16px; opacity: 0.7;">
                                                                Create Template
                                                            </v-toolbar-title>
                                                        </v-toolbar>
                                                        <v-card-text>
                                                            <v-text-field v-model="templateCreate.title" outlined dense hide-details label="Title" placeholder="Exporter View" persistent-placeholder></v-text-field>
                                                            <v-subheader style="height: 30px;" class="px-0 mt-3"> 
                                                                Available To
                                                            </v-subheader>
                                                            <v-divider></v-divider>
                                                            <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;">
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Everyone')" :color="templateCreate.allowedParties.includes('Everyone') ? 'tertiary' : ''"  @click="templateTag('Everyone')">Everyone</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Forwarder')" :color="templateCreate.allowedParties.includes('Forwarder') ? 'tertiary' : ''"  @click="templateTag('Forwarder')">Forwarder</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Shipper')" :color="templateCreate.allowedParties.includes('Shipper') ? 'tertiary' : ''"  @click="templateTag('Shipper')">Shipper</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Consignee')" :color="templateCreate.allowedParties.includes('Consignee') ? 'tertiary' : ''"  @click="templateTag('Consignee')">Consignee</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Buyer')" :color="templateCreate.allowedParties.includes('Buyer') ? 'tertiary' : ''"  @click="templateTag('Buyer')">Buyer</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Transporter')" :color="templateCreate.allowedParties.includes('Transporter') ? 'tertiary' : ''"  @click="templateTag('Transporter')">Transporter</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Shipper')" :color="templateCreate.allowedParties.includes('On Behalf Shipper') ? 'tertiary' : ''"  @click="templateTag('On Behalf Shipper')">On Behalf Shipper</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Consignee')" :color="templateCreate.allowedParties.includes('On Behalf Consignee') ? 'tertiary' : ''"  @click="templateTag('On Behalf Consignee')">On Behalf Consignee</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Forwarder')" :color="templateCreate.allowedParties.includes('On Behalf Forwarder') ? 'tertiary' : ''"  @click="templateTag('On Behalf Forwarder')">On Behalf Forwarder</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Buyer')" :color="templateCreate.allowedParties.includes('On Behalf Buyer') ? 'tertiary' : ''"  @click="templateTag('On Behalf Buyer')">On Behalf Buyer</v-chip>
                                                            </div>
                                                            <v-subheader style="height: 30px;" class="px-0 mt-3">
                                                                Tags
                                                            </v-subheader>
                                                            <v-divider></v-divider>
                                                            <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;">
                                                                <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Logistical Docs')" :color="templateCreate.tags.includes('Logistical Docs') ? 'greyBase' : ''" small @click="templateTag('Logistical Docs', 'tags')">Logistical Docs</v-chip>
                                                                <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Finance')" :color="templateCreate.tags.includes('Finance') ? 'greyBase' : ''" small @click="templateTag('Finance', 'tags')">Finance</v-chip>
                                                                <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Bookings')" :color="templateCreate.tags.includes('Bookings') ? 'greyBase' : ''" small @click="templateTag('Bookings', 'tags')">Bookings</v-chip>
                                                                <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Client')" :color="templateCreate.tags.includes('Client') ? 'greyBase' : ''" small @click="templateTag('Client', 'tags')">Client</v-chip>
                                                            </div>
                                                            <div class="mt-3 d-flex justify-center align-center">
                                                                <v-btn color="primary" :disabled="!templateCreate.title" @click="saveTemplate('OrgTemplate')" :loading="loadingTemplateSave">Save</v-btn>
                                                            </div>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="loadingTemplates">
                                            <v-col cols="12" sm="12" md="6" lg="6" v-for="(i) in 4" :key="i" class="px-1 py-1">
                                                <v-card flat class="d-flex pa-1 align-center" style="border-radius: 16px; width: 100%; aspect-ratio: 3/1; background: var(--v-greyRaised-lighten2) !important;">
                                                    <div style="max-height: 67px; max-width: 67px; height: 67px; width: 67px; contain: content; border-radius: 16px; overflow: hidden;">
                                                        <v-skeleton-loader type="image"></v-skeleton-loader>
                                                    </div>
                                                    <v-skeleton-loader class="ml-2" style="width: 60%;" type="sentences"></v-skeleton-loader>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <v-subheader style="height: 30px;" class="px-0">
                                            Your Templates
                                        </v-subheader>
                                        <v-row no-gutters v-if="!loadingTemplates">
                                            <v-col cols="12" sm="12" md="6" lg="6" v-for="(template, index) in userTemplates" :key="index" class="px-1 py-1">
                                                <v-hover v-slot="{ hover }">
                                                    <v-card @click="changeTemplate(template, index, 'UserTemplates')" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center pa-1" style="cursor: pointer; border-radius: 16px; width: 100%; aspect-ratio: 3/1;" :style="selectedTemplate?.id == template.id ? {'background': 'var(--v-primary-darken2) !important'} : {'background': 'var(--v-greyRaised-lighten2) !important'}">
                                                        <v-card height="100%" style=" border-radius: 16px; aspect-ratio: 1/1; flex-direction: column; background: var(--v-greyRaised-lighten1) !important; opacity: 0.5;" class="mr-2 d-flex justify-center align-center">
                                                            <v-icon>{{template.templateType == 'Table' ? 'reorder' : template.templateType == 'Dashboard' ? 'dashboard' : 'list' }}</v-icon>
                                                            <span style="font-size: 10px;">{{template.templateType}}</span>
                                                        </v-card>
                                                        <span style="text-align: center; font-size: 12px;" >{{ template.title }}</span>
                                                    </v-card>
                                                </v-hover>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6" lg="6" class="d-flex justify-center align-center" align-self="center">
                                                <v-menu bottom offset-y :close-on-content-click="false">
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-hover v-slot="{ hover }">
                                                            <v-card v-on="on" v-bind="attrs" :elevation="hover ? 12 : 0" :flat="!hover" class="d-flex align-center pa-1" style="border: 1px dashed rgb(135, 135, 135); cursor: pointer; border-radius: 16px; width: 100%; aspect-ratio: 3/1; background: var(--v-greyRaised-lighten1) !important" >
                                                                <v-card height="100%" style=" border-radius: 16px; aspect-ratio: 1/1; flex-direction: column; background: var(--v-greyRaised-lighten2) !important; opacity: 0.4;" class="mr-2 d-flex justify-center align-center">
                                                                    <v-icon>add_circle</v-icon>
                                                                </v-card>
                                                                <span style="text-align: center; font-size: 12px;" >Create Template</span>
                                                            </v-card>
                                                        </v-hover>
                                                    </template>
                                                    <v-card style="background-color: var(--v-greyRaised-lighten1) !important; max-width: 400px; width: 400px;">
                                                        <v-toolbar flat dense height="35px">
                                                            <v-toolbar-title style="font-size: 16px; opacity: 0.7;">
                                                                Create Template
                                                            </v-toolbar-title>
                                                        </v-toolbar>
                                                        <v-card-text>
                                                            <v-text-field v-model="templateCreate.title" outlined dense hide-details label="Title" placeholder="Exporter View" persistent-placeholder></v-text-field>
                                                            <v-subheader style="height: 30px;" class="px-0 mt-3"> 
                                                                Available To
                                                            </v-subheader>
                                                            <v-divider></v-divider>
                                                            <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;">
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Everyone')" :color="templateCreate.allowedParties.includes('Everyone') ? 'tertiary' : ''"  @click="templateTag('Everyone')">Everyone</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Forwarder')" :color="templateCreate.allowedParties.includes('Forwarder') ? 'tertiary' : ''"  @click="templateTag('Forwarder')">Forwarder</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Shipper')" :color="templateCreate.allowedParties.includes('Shipper') ? 'tertiary' : ''"  @click="templateTag('Shipper')">Shipper</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Consignee')" :color="templateCreate.allowedParties.includes('Consignee') ? 'tertiary' : ''"  @click="templateTag('Consignee')">Consignee</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Buyer')" :color="templateCreate.allowedParties.includes('Buyer') ? 'tertiary' : ''"  @click="templateTag('Buyer')">Buyer</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('Transporter')" :color="templateCreate.allowedParties.includes('Transporter') ? 'tertiary' : ''"  @click="templateTag('Transporter')">Transporter</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Shipper')" :color="templateCreate.allowedParties.includes('On Behalf Shipper') ? 'tertiary' : ''"  @click="templateTag('On Behalf Shipper')">On Behalf Shipper</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Consignee')" :color="templateCreate.allowedParties.includes('On Behalf Consignee') ? 'tertiary' : ''"  @click="templateTag('On Behalf Consignee')">On Behalf Consignee</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Forwarder')" :color="templateCreate.allowedParties.includes('On Behalf Forwarder') ? 'tertiary' : ''"  @click="templateTag('On Behalf Forwarder')">On Behalf Forwarder</v-chip>
                                                                <v-chip class="mx-1 my-1" small :outlined="!templateCreate.allowedParties.includes('On Behalf Buyer')" :color="templateCreate.allowedParties.includes('On Behalf Buyer') ? 'tertiary' : ''"  @click="templateTag('On Behalf Buyer')">On Behalf Buyer</v-chip>
                                                            </div>
                                                            <v-subheader style="height: 30px;" class="px-0 mt-3">
                                                                Tags
                                                            </v-subheader>
                                                            <v-divider></v-divider>
                                                            <div class="d-flex align-center justify-center mt-3" style="flex-wrap: wrap;">
                                                                <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Logistical Docs')" :color="templateCreate.tags.includes('Logistical Docs') ? 'greyBase' : ''" small @click="templateTag('Logistical Docs', 'tags')">Logistical Docs</v-chip>
                                                                <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Finance')" :color="templateCreate.tags.includes('Finance') ? 'greyBase' : ''" small @click="templateTag('Finance', 'tags')">Finance</v-chip>
                                                                <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Bookings')" :color="templateCreate.tags.includes('Bookings') ? 'greyBase' : ''" small @click="templateTag('Bookings', 'tags')">Bookings</v-chip>
                                                                <v-chip class="mx-1 my-1" :outlined="!templateCreate.tags.includes('Client')" :color="templateCreate.tags.includes('Client') ? 'greyBase' : ''" small @click="templateTag('Client', 'tags')">Client</v-chip>
                                                            </div>
                                                            <div class="mt-3 d-flex justify-center align-center">
                                                                <v-btn color="primary" :disabled="!templateCreate.title" @click="saveTemplate('UserTemplate')" :loading="loadingTemplateSave">Save</v-btn>
                                                            </div>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters v-if="loadingTemplates">
                                            <v-col cols="12" sm="12" md="6" lg="6" v-for="(i) in 4" :key="i" class="px-1 py-1">
                                                <v-card flat class="d-flex pa-1 align-center" style="border-radius: 16px; width: 100%; aspect-ratio: 3/1; background: var(--v-greyRaised-lighten2) !important;">
                                                    <div style="max-height: 67px; max-width: 67px; height: 67px; width: 67px; contain: content; border-radius: 16px; overflow: hidden;">
                                                        <v-skeleton-loader type="image"></v-skeleton-loader>
                                                    </div>
                                                    <v-skeleton-loader class="ml-2" style="width: 60%;" type="sentences"></v-skeleton-loader>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                            <v-chip small dense class="mr-2 px-2" color="var(--v-redPop-darken1)" v-if="filtersActive" @click="clearFilters"><v-icon small class="pr-2" >filter_list_off</v-icon>Clear Filters</v-chip>
                            <v-spacer></v-spacer>
                            <span class="mr-1 text"><b>{{ currentWeek ?? '' }}</b></span>
                            <div style="width: 20vw;">
                                <v-chip style="display: grid" class="px-2">
                                    <v-tooltip top open-delay="500">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" x-small icon color="primary" @click="addOrder()">
                                                <v-icon>add_circle_outline</v-icon>
                                            </v-btn>
                                        </template>
                                        Create Order
                                    </v-tooltip>
                                    <v-tooltip top open-delay="500">
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" x-small icon color="teal" @click="openAvailableBookings()">
                                                <v-icon>add_circle_outline</v-icon>
                                            </v-btn>
                                        </template>
                                        Create Order From Booking
                                    </v-tooltip>
                                    <v-icon small color="grey" class="ml-1">search</v-icon>
                                    <v-text-field placeholder="Search" class="mb-1 searchInput" hide-details rounded clearable dense v-model="search" ></v-text-field>
                                    <v-tooltip top v-if="filtersActive">
                                        <template v-slot:activator="{ on }">
                                          <v-btn rounded x-small icon color="var(--v-redPop-darken1)" @click="clearFilters" v-on="on" >
                                            <v-icon>filter_list_off</v-icon>
                                          </v-btn>
                                        </template>
                                        <span style="font-size: 0.75rem">Clear Filters</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon x-small color="blue" @click="getContainers" v-on="on" >
                                                <v-icon>autorenew</v-icon>
                                            </v-btn>
                                        </template>
                                        <span style="font-size: 0.75rem">Refresh</span>
                                    </v-tooltip>
                                </v-chip>
                            </div>
                            <v-menu bottom offset-y nudge-right :close-on-content-click="false" v-if="showCharts">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip v-on="on" v-bind="attrs" small class="px-1 ml-1" color="var(--v-greyRaised-lighten3)" >
                                        <v-icon small>settings</v-icon>
                                    </v-chip>
                                </template>
                                <v-card class="px-2 pb-2" style="background: var(--v-greyDarker-base) !important">
                                    <v-subheader style="height: 30px;" class="px-0">
                                        Graphs
                                        <v-spacer></v-spacer>
                                        <v-chip class="px-1" small @click="graphsLock = !graphsLock" :color="graphsLock ? 'grey' : 'greyBase'"><v-icon small>{{graphsLock ? 'lock' : 'lock_open'}}</v-icon></v-chip>
                                    </v-subheader>
                                    <div class="d-flex" style="gap: 5px;">
                                        <v-card :disabled="graphsLock" @click="graphs[0].enabled = !graphs[0].enabled" :color="graphs[0].enabled ? 'var(--v-greyBase-base) !important' : 'var(--v-greyRaised-base) !important'" class="graphState">
                                            <b>1</b>
                                            <v-icon size="80" color="grey">stacked_bar_chart</v-icon>
                                        </v-card>
                                        <v-card :disabled="graphsLock" @click="graphs[1].enabled = !graphs[1].enabled" :color="graphs[1].enabled ? 'var(--v-greyBase-base) !important' : 'var(--v-greyRaised-base) !important'" class="graphState">
                                            <b>2</b>
                                            <v-icon size="80" color="grey">pie_chart</v-icon>
                                        </v-card>
                                        <v-card :disabled="graphsLock" @click="graphs[2].enabled = !graphs[2].enabled" :color="graphs[2].enabled ? 'var(--v-greyBase-base) !important' : 'var(--v-greyRaised-base) !important'" class="graphState">
                                            <b>3</b>
                                            <v-icon size="80" color="grey">area_chart</v-icon>
                                        </v-card>
                                        <v-card :disabled="graphsLock" @click="graphs[3].enabled = !graphs[3].enabled" :color="graphs[3].enabled ? 'var(--v-greyBase-base) !important' : 'var(--v-greyRaised-base) !important'" class="graphState">
                                            <b>4</b>
                                            <v-icon size="80" color="grey">bubble_chart</v-icon>
                                        </v-card>
                                    </div>
                                </v-card>
                            </v-menu>
                            <v-chip small class="px-1 ml-1" :color="showCharts ? 'tertiary' : 'var(--v-greyRaised-lighten3)'" @click="showChart" v-if="$store.state.user.id == 69">
                                <v-icon small>query_stats</v-icon>
                            </v-chip>
                        </div>
                        <div v-if="showCharts" style="height: 35vh; min-height: 50px" class="topSection">
                            <div class="graphGrid g1" id="graphGrid-1" v-if="graphs[0].enabled" :style="{'width': `calc((100vw - (${graphs.filter(x => x.enabled).length * 1}px)) / ${graphs.filter(x => x.enabled).length} )`}"></div>
                            <div class="gutter1" @mousedown="handleResizeGraphs('.gutter1')" v-if="!graphsLock && graphs.some((x, i) => i > 0 && x.enabled ) && graphs[0].enabled"><div class="vertHandle"></div></div>
                            <div class="graphGrid g2" id="graphGrid-2" v-if="graphs[1].enabled" :style="{'width': `calc((100vw - (${graphs.filter(x => x.enabled).length * 1}px)) / ${graphs.filter(x => x.enabled).length} )`}"></div>
                            <div class="gutter2" @mousedown="handleResizeGraphs('.gutter2')" v-if="!graphsLock && graphs.some((x, i) => i > 1 && x.enabled ) && graphs[1].enabled"><div class="vertHandle"></div></div>
                            <div class="graphGrid g3" id="graphGrid-3" v-if="graphs[2].enabled" :style="{'width': `calc((100vw - (${graphs.filter(x => x.enabled).length * 1}px)) / ${graphs.filter(x => x.enabled).length} )`}"></div>
                            <div class="gutter3" @mousedown="handleResizeGraphs('.gutter3')" v-if="!graphsLock && graphs.some((x, i) => i > 2 && x.enabled ) && graphs[2].enabled"><div class="vertHandle"></div></div>
                            <div class="graphGrid g4" id="graphGrid-4" v-if="graphs[3].enabled" :style="{'width': `calc((100vw - (${graphs.filter(x => x.enabled).length * 1}px)) / ${graphs.filter(x => x.enabled).length} )`}"></div>
                        </div>
                        <div v-if="showCharts" class="gutter" @mousedown="handleResizeMain()"><div class="handle"></div></div>
                        <div :style="showCharts ? {'height': 'calc(100vh - 35vh - 66px)'} : {'height': 'calc(100vh - 66px)'}" style="width: auto; overflow-x: scroll; min-height: 30vh" class="bottomSection">
                            <ag-grid-vue style="width: auto; height: 100%;"
                                class="ag-grid-theme-builder"
                                :columnDefs="headers"
                                :chartThemes="chartThemes"
                                :chartThemeOverrides="chartThemeOverrides"
                                :autoSizeStrategy="autoSizeStrategy"
                                :suppressAggFuncInHeader="true"
                                :loading="loading"
                                :hideOverlay="loading"
                                :rowData="containers"
                                :autoGroupColumnDef="autoGroupColumnDef"
                                :defaultColDef="defaultColumn"
                                :enableCharts="enableCharts"
                                :popupParent="popUpParent"
                                :enableRangeSelection="enableRangeSelection"
                                :rowGroupPanelShow="rowGroupPanelShow"
                                :loadingOverlayComponent="loadingOverlayComponent"
                                :statusBar="statusBar"
                                :sideBar="sideBarDef"
                                :getContextMenuItems="getContextMenuItems"
                                :chartToolPanelsDef="chartToolPanelsDef"
                                @grid-ready="onGridReady"
                                @columnMoved="columnsChanged"
                                @displayedColumnsChanged="columnsChanged"
                                @columnResized="columnsChanged"
                                @columnRowGroupChanged="columnsChanged"
                                @columnPivotModeChanged="columnsChanged"
                                :createChartContainer="$store.state.user.id == 63 ? createChartContainer : null"
                            >
                            </ag-grid-vue>
                        </div>
                    </div>
                </div>
                <v-dialog v-model="orderModal" persistent width="75vw" style="height: auto;" class="rightDialog">
                  <CreateOrder
                    :order="createOrderData"
                    :organisations="organisations"
                    :loadingRelated="loadingOrganisations"
                    @close="(orderModal = false), (createOrderData = {})"
                    @refresh="getContainers()"
                  />
                </v-dialog>
                <v-dialog v-model="bookingModal" width="800px">
                  <v-card :loading="loadingBookings">
                    <v-toolbar dense flat>
                      <v-toolbar-title>Available Bookings</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn plain icon small @click="bookingModal = false"><v-icon color="redPop">close</v-icon></v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-text-field v-model="searchBookings" placeholder="Search" outlined dense clearable prepend-inner-icon="search" ></v-text-field>
                      <div class="loadingContainer" v-if="!loadingBookings && bookingsToConvert.data.length == 0" >
                        <h3 style="color: grey">No Available Bookings</h3>
                      </div>
                      <div class="loadingContainer" v-else-if="loadingBookings">
                        <h3 style="color: grey">Loading...</h3>
                      </div>
                      <v-virtual-scroll v-else-if="bookingsToConvert.data.length > 0" :items="availableBookings" item-height="200" height="500" >
                        <template v-slot:default="{ item }">
                          <v-card style=" border-radius: 20px; background: var(--v-component-base) !important; " @click="confirmBookingToOrder(item)" >
                          <v-toolbar flat dense>
                            <v-toolbar-title style="font-size: 14px">
                              {{ item.orderNo }}
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-chip small outlined color="primary" v-if="item.vessel">
                              <v-icon small left>directions_boat</v-icon>
                              {{ item.vessel.name }}
                              <span right>{{ item.mainCarriageConveyanceNumber }}</span>
                            </v-chip>
                          </v-toolbar>
                            <v-card-text>
                              <v-row justify="center">
                                <v-col cols="12" sm="4">
                                  <v-list dense subheader>
                                    <v-subheader>Parties</v-subheader>
                                    <v-divider></v-divider>
                                    <!-- Shipper -->
                                    <v-list-item style="height: 35px" v-if="item.shipper">
                                      <v-list-item-action>
                                        <v-avatar :color="item.shipper.logo ? 'white' : 'secondary'" size="32" >
                                          <v-img v-if="item.shipper.logo" :src="item.shipper.logo" contain ></v-img>
                                          <h3 v-else>{{ item.shipper.name.charAt(0) }}</h3>
                                        </v-avatar>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">{{
                                          item.shipper.name
                                        }}</v-list-item-title>
                                         <v-list-item-subtitle style="font-size: 12px">
                                          Shipper
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!--     Forwarder -->
                                    <v-list-item style="height: 35px" v-if="item.forwarder">
                                      <v-list-item-action>
                                        <v-avatar :color="item.forwarder.logo ? 'white' : 'secondary'" size="32" >
                                            <v-img v-if="item.forwarder.logo" :src="item.forwarder.logo" contain ></v-img>
                                          <h3 v-else>{{ item.forwarder.name.charAt(0) }}</h3>
                                        </v-avatar>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">{{ item.forwarder.name }}</v-list-item-title>
                                         <v-list-item-subtitle style="font-size: 12px">
                                          Forwarder
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!-- Buyer -->
                                    <v-list-item style="height: 35px" v-if="item.buyer">
                                      <v-list-item-action>
                                        <v-avatar :color="item.buyer.logo ? 'white' : 'secondary'" size="32" >
                                          <v-img v-if="item.buyer.logo" :src="item.buyer.logo" contain ></v-img>
                                          <h3 v-else>{{ item.buyer.name.charAt(0) }}</h3>
                                        </v-avatar>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">{{ item.buyer.name }}</v-list-item-title>
                                         <v-list-item-subtitle style="font-size: 12px">
                                          Buyer
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                      <!-- Consignee -->
                                      <v-list-item style="height: 35px" v-if="item.consignee">
                                      <v-list-item-action>
                                        <v-avatar :color="item.consignee.logo ? 'white' : 'secondary'" size="32" >
                                          <v-img v-if="item.consignee.logo" :src="item.consignee.logo" contain ></v-img>
                                          <h3 v-else>{{ item.consignee.name.charAt(0) }}</h3>
                                        </v-avatar>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">{{ item.consignee.name }}</v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px">
                                          Consignee
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                                <v-col cols="4">
                                  <v-list dense subheader>
                                    <v-subheader>Details</v-subheader>
                                    <v-divider></v-divider>
                                    <v-list-item style="height: 35px">
                                      <v-list-item-action>
                                        <v-icon small color="grey">anchor</v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">
                                          <span v-if="item.portOfLoadValue">
                                            {{ item.portOfLoadValue }}
                                          </span>
                                      <span v-else>-</span>
                                    </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px">
                                          Port Of Load
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-avatar size="28" v-if="item.portOfLoadValue"> <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue .substring(0, 2) .toLowerCase()}.svg`" />
                                            </v-avatar>
                                      </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item style="height: 35px">
                                      <v-list-item-action>
                                        <v-icon small color="grey">anchor</v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">
                                          <span v-if="item.portOfDischargeValue">
                                            {{ item.portOfDischargeValue }}
                                          </span>
                                      <span v-else>-</span>
                                    </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px">
                                          Port Of Discharge
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-avatar size="28" v-if="item.portOfDischargeValue">
                                              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.portOfDischargeValue .substring(0, 2) .toLowerCase()}.svg`" />
                                            </v-avatar>
                                      </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item style="height: 35px">
                                      <v-list-item-action>
                                        <v-icon small color="grey">location_on</v-icon>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title style="font-size: 12px">
                                          <span v-if="item.finalDestinationValue">
                                            {{ item.finalDestinationValue }}
                                          </span>
                                      <span v-else>-</span>
                                    </v-list-item-title>
                                        <v-list-item-subtitle style="font-size: 12px">
                                          Final Destination
                                        </v-list-item-subtitle>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-avatar size="28" v-if="item.finalDestinationValue">
                                              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue .substring(0, 2) .toLowerCase()}.svg`" />
                                            </v-avatar>
                                      </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item style="height:35px">
                                    <v-list-item-action>
                                      <v-icon small color="grey">event</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title style="font-size: 12px">
                                        {{ $Format.formatDate(item.etd).dayMonthYear }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="font-size: 12px">
                                        ETD
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item style="height:35px">
                                    <v-list-item-action>
                                      <v-icon small color="grey">event</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title style="font-size: 12px">
                                        {{ $Format.formatDate(item.eta).dayMonthYear }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle style="font-size: 12px">
                                        ETA
                                      </v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                                <v-col cols="4">
                              <v-list dense subheader>
                                <v-subheader>Cargo</v-subheader>
                                <v-divider></v-divider>
                                <v-list-item style="height: 35px">
                                  <v-list-item-content>
                                    <v-list-item-title style="font-size: 12px">
                                      {{ item.numContainers }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                      Containers
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-subheader>References</v-subheader>
                                <v-divider></v-divider>
                                <v-list-item style="height: 35px">
                                  <v-list-item-content>
                                    <v-list-item-title style="font-size: 12px">
                                      {{ item.shipperReferenceNo }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                      Shipper Ref. No
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item style="height: 35px">
                                  <v-list-item-content>
                                    <v-list-item-title style="font-size: 12px">
                                      {{ item.consigneeReferenceNo }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                      Consignee Ref. No
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item style="height: 35px">
                                  <v-list-item-content>
                                    <v-list-item-title style="font-size: 12px">
                                      {{ item.carrierReferenceNumber }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                      Carrier Ref. No
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                  </v-list-item>
                              </v-list>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </template>
                      </v-virtual-scroll>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="confirmBookingConversionModal" width="500px">
                    <v-card :loading="convertingBooking">
                      <v-toolbar dense flat color="transparent">
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text>
                        <p style="font-size: 14px">
                          Are you sure you would like to convert <b>{{selectedBookingToConvert.orderNo}}</b> into an Order?
                        </p>
                        <v-row justify="center" class="mt-5 mb-3">
                          <v-btn color="red" class="mx-2" text small @click="confirmBookingConversionModal = false" >No</v-btn >
                          <v-btn color="success" class="mx-2" text small @click="convertBooking(selectedBookingToConvert)" >Yes</v-btn >
                        </v-row>
                      </v-card-text>
                    </v-card>
                </v-dialog>
            </v-tab-item>
            <v-tab-item value="tab-Forecasts" style="height: 100%;">
                <Forecasts />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import * as moment from 'moment'
import {ref} from 'vue'
import CreateOrder from '../components/Orders/CreateOrder.vue'
import Forecasts from '../components/TradingView/OrderForecasts.vue'
/* eslint-disable vue/no-unused-components */
import CellRenders from '../components/DynamicTables/AgGrid/CellRenders.vue'
import LoadingOverlay from '../components/DynamicTables/AgGrid/LoadingOverlay.vue'
/* eslint-disable vue/no-unused-components */
export default {
    components: {
        CreateOrder,
        AgGridVue,
        CellRenders,
        Forecasts,
        LoadingOverlay
    },
    data: () => ({
        showCharts: false,
        rowGroupPanelShow: 'onlyWhenGrouping',
        chartToolPanelsDef: {
            defaultToolPanel: "settings",
            panels: ['data', 'format', 'settings']
        },
        autoSizeStrategy: 'fitCellContents',
        loadingOverlayComponent: 'LoadingOverlay',
        sideBarDef: {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
            defaultToolPanel: '',
        },
        defaultColumn: {
            sortable: true,
            filter: 'agMultiColumnFilter',
            enableRowGroup: true,
            cellClass: 'd-flex align-center',
            cellRenderer: 'CellRenders',
            enablePivot: true,
        },
        graphsLock: false,
        graphs: [
            {enabled: true, elementId: '#graphGrid-1'},
            {enabled: false, elementId: '#graphGrid-2'},
            {enabled: false, elementId: '#graphGrid-3'},
            {enabled: false, elementId: '#graphGrid-4'},
        ],
        chartThemeOverrides: {
            common: {
                    background: {
                        fill: '#333333',
                    },
                    axes: {
                        number: {
                        
                        },
                        category: {
                            label: {
                                color: 'white',
                            },
                            
                        }
                    },
                },
        },
        headers: [
            {
                headerName: "Week-Year ETD",
                field: "etdWeekYear",
            },
            {
                headerName: "Booking Status",
                field: "state",
            },
            {
                headerName: "Order Status",
                field: "orderStatus",
            },
            {
                headerName: "OrderNo",
                field: "orderNo",
            },
            {
                headerName: "Allocated",
                field: "stockAllocationStatus",
                cellClass: 'd-flex align-center justify-center',
                cellRendererParams: {type: 'stockAllocationStatus'},
                getQuickFilterText: params => {
                    return ''
                },
            },
            {
                headerName: "LL No",
                field: "ctoNo",
            },
            {
                headerName: "SType",
                field: "serviceType",
            },
            {
                headerName: "Container No",
                field: "containerNo",
                aggFunc: 'count',
                enableValue: true,
            },
            {
                headerName: "Features",
                field: "features",
                getQuickFilterText: params => {
                    return ''
                },
                filter: false,
                cellClass: 'd-flex align-center justify-center',
                cellRenderer: 'CellRenders',
                cellRendererParams: (params) => {
                    return {
                        type: 'features',
                        propData: params.data?.temporaryProducts ?? null
                    }
                }
            },
            {
                headerName: "Products",
                field: "product",
                cellClass: 'd-flex align-center justify-center',
                getQuickFilterText: params => {
                    let result = ''
                    if(params.data?.temporaryProducts) {
                        let array = params.data.temporaryProducts.map((item) => {
                            return `${item.name ?? ''} ${item.code ?? ''}`
                        })
                        result = array.join(' ')
                    }
                    return result
                },
            },
            {
                headerName: "Shipper",
                field: "shipperName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.shipper ?? null
                    }
                }
            },
            {
                headerName: "ShipperRef",
                field: "shipperReferenceNo",
            },
            {
                headerName: "Buyer",
                field: "buyerName",
                chartDataType: 'category',
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.buyer ?? null
                    }
                }
            },
            {
                headerName: "Consignee",
                field: "consigneeName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.consignee ?? null
                    }
                }
            },
            {
                headerName: "Consignee Ref",
                field: "consigneeReferenceNo",
            },
            {
                headerName: "Forwarder",
                field: "forwarderName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.forwarder ?? null
                    }
                }
            },
            {
                headerName: "Forwarder Ref",
                field: "fileNumber",
            },
            {
                headerName: "Carrier Ref",
                field: "carrierReferenceNumber",
            },
            {
                headerName: "On Behalf Shipper",
                field: "onBehalfShipperName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.onBehalfShipper ?? null
                    }
                }
            },
            {
                headerName: "On Behalf Forwarder",
                field: "onBehalfForwarderName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.onBehalfForwarder ?? null
                    }
                }
            },
            {
                headerName: "On Behalf Buyer",
                field: "onBehalfBuyerName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.onBehalfBuyer ?? null
                    }
                }
            },
            {
                headerName: "On Behalf Consignee",
                field: "onBehalfConsigneeName",
                cellRendererParams: (params) => {
                    return {
                        type: 'organisation',
                        propData: params.data?.onBehalfConsignee ?? null
                    }
                }
            },
            {
                headerName: "Docs",
                field: "fileStatus",
            },
            {
                headerName: "Carrier",
                field: "shippingLineName",
                cellRendererParams: (params) => {
                    return {
                        type: 'shippingLine',
                        propData: params.data?.shippingLine ?? null
                    }
                }
            },
            {
                headerName: "Vessel",
                field: "vesselVoyage",
            },
            {
                headerName: "Origin Country",
                field: "originCountryName",
            },
            {
                headerName: "POL",
                field: "portOfLoadValue",
                cellRendererParams:{
                    type: 'locode'
                }
            },
            {
                headerName: "POD",
                field: "portOfDischargeValue",
                cellRendererParams:{
                    type: 'locode'
                }
            },
            {
                headerName: "Final Dest",
                field: "finalDestinationValue",
                cellRendererParams:{
                    type: 'locode'
                }
            },
            {
                headerName: "Dest Country",
                field: "destinationCountryName",
            },
            {
                headerName: 'Week ETA',
                field: 'etaWeek'
            },
            {
                headerName: 'Year ETA',
                field: 'etaYear'
            },
            {
                headerName: 'Week ETD',
                field: 'etdWeek'
            },
            {
                headerName: 'Year ETD',
                field: 'etdYear'
            },
            {
                headerName: "Week-Year ETA",
                field: "etaWeekYear",
            },
            {
                headerName: "ETD",
                field: "etd",
                filterParams: {
                    filters: [
                        {
                            filter: 'agDateColumnFilter',
                            display: 'subMenu',
                        },
                        {
                            filter: 'agSetColumnFilter',
                        }
                    ]
                }
            },
            {
                headerName: "ETA",
                field: "eta",
                filterParams: {
                    filters: [
                        {
                            filter: 'agDateColumnFilter',
                            display: 'subMenu',
                        },
                        {
                            filter: 'agSetColumnFilter',
                        }
                    ]
                }
            },
            {
                headerName: "Pallets",
                field: "totalPallets",
                aggFunc: 'sum',
                chartDataType: 'series',
                enableValue: true,
                filterParams: {
                    filters: [
                        {
                            filter: 'agNumberColumnFilter',
                            display: 'subMenu',
                        },
                        {
                            filter: 'agSetColumnFilter',
                        }
                    ]
                }
            },
            {
                headerName: "Units ",
                field: "totalPackageCount",
                aggFunc: 'sum',
                chartDataType: 'series',
                enableValue: true,
                filterParams: {
                    filters: [
                        {
                            filter: 'agNumberColumnFilter',
                            display: 'subMenu',
                        },
                        {
                            filter: 'agSetColumnFilter',
                        }
                    ]
                }
            },
            {
                headerName: "INCO (FF)",
                field: "incoTerm",
            },
            {
                headerName: "Deal Term",
                field: "financialType",
            },
            {
                headerName: "INCO",
                field: "financialIncoTerm",
            },
            {
                headerName: "Freight Term",
                field: "freightTerm",
            },
            
        ],
        tab: null,  
        rightCard: false,
        dateMenu: false,
        statusBar: {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent' },
                { statusPanel: 'agTotalRowCountComponent' },
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
                { statusPanel: 'agAggregationComponent' }
            ]
        },
        autoGroupColumnDef: {
            headerName: 'Model',
            field: 'model',
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                checkbox: true
            }
        },
        templateCreate: {
            title: null,
            allowedParties: ['Everyone'],
            tags: [],
            filters: [],
        },
        selectedTabs: 'tab-Containers',
        tabs: [
            {
                icon: 'list',
                name: 'Containers'
            },
            {
                icon: 'event_upcoming',
                name: 'Forecasts'
            },
        ],
        selectedTemplate: null,
        createOrderData : {},
        orderModal: false,
        shortcutKey: 123,
        chartThemes: ['ag-polychroma-dark', 'ag-vivid-dark', 'ag-material-dark', 'ag-sheets-dark', 'ag-default-dark'],
        customChartThemes: {
            myCustomTheme: {
                palette: {
                    fills: ['#42a5f5', '#ffa726', '#81c784'],
                    strokes: ['#000000', '#424242'],
                },
                overrides: {
                    common: {
                        background: {
                            fill: '#f4f4f4',
                        },
                        legend: {
                            item: {
                                label: {
                                    color: '#333333',
                                },
                            },
                        },
                    },
                },    
            },
        },
        searchBookings: '',
        convertingBooking: false,
        enableCharts: true,
        canUpdateOwnTemplate: false,
        canUpdateOrgTemplate: false,
        enableRangeSelection: true,
        gridApi: null,
        vesselVoyageSet: [],
        shippingLinesSet: [],
        bookingsToConvert: { data: [] },
        selectedBookingToConvert: {},
        popUpParent: null,
        confirmBookingConversionModal: false,
        loadingBookings: false,
        gridLoaded: false,
        bookingModal: false,
        bookingQuery: undefined,
        bookingTimeout: undefined,
        loading: true,
        userTemplates: [],
        updateKeys: 0,
        shortcutKeyChip: 1154,
        loadingTemplateSave: false,
        templates: [],
        baseKey: 1,
        primaryKey: 123,
        loadingTemplates: false,
        secondaryKey: 456,
        searchTimeout: undefined,
        tertiaryKey: 789,
        collapsed: false,
        loadingOrganisations: false,
        organisations: {
          total: 0,
          data: [],
        },
        currentWeek: null,
        search: '',
        status: [
            {
                name: 'Planned',
                color: '#9d5aa8'
            },
            {
                name: 'Awaiting Departure',
                color: '#dc9f44'
            },
            {
                name: 'In Transit',
                color: '#417eaf'
            },
            {
                name: 'Arrived',
                color: '#4c8d4f'
            },
            {
                name: 'Cancelled',
                color: '#cd584f'
            }
        ],
        containers : null,
        filtersActive: false,
        filterModel: {},
    }),
    watch: {
        updateKeys: {
          handler(val) {
              this.baseKey++
              this.primaryKey++
              this.secondaryKey++
              this.tertiaryKey++
          }
        },
        search: {
            immediate: true,
            handler(val) {
                if(this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                }
                this.searchTimeout = setTimeout(() => {
                    this.searchTimeout = undefined
                    this.gridApi.setGridOption('quickFilterText', val)
                }, 500)
            }
        },
        showCharts: {
            immediate: true,
            handler(val) {
                localStorage.setItem(`showCharts-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, val)
            }
        }
    },
    beforeMount(){
        this.popUpParent = document.querySelector("body")
    },
    mounted() {
        this.loading = true
        if(localStorage.getItem(`showCharts-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`)) {
            this.showCharts = JSON.parse(localStorage.getItem(`showCharts-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`))
            this.initResizeMain()
            this.initResizeGraphs('.gutter1')
            this.initResizeGraphs('.gutter2')
            this.initResizeGraphs('.gutter3')
        }
        this.setCurrentWeek()
    },
    created () {
        this.loading = true
        this.getContainers()
        this.getRelations()
        this.setCurrentWeek()
    },
    computed: {
        availableBookings(){
          let result = this.bookingsToConvert.data ? JSON.parse(JSON.stringify(this.bookingsToConvert.data)) : []
          if(this.searchBookings && result.length > 0) {
            result = result.filter(x =>
            x.orderNo.toLowerCase().includes(this.searchBookings.toLowerCase()) ||
            (x.consigneeName && x.consigneeName.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.shipperName && x.shipperName.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.buyerName && x.buyerName.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.vessel && x.vessel.name.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.mainCarriageConveyanceNumber && x.mainCarriageConveyanceNumber.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.carrierReferenceNumber && x.carrierReferenceNumber.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.shipperReferenceNo && x.shipperReferenceNo.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
            (x.consigneeReferenceNo && x.consigneeReferenceNo.toLowerCase().includes(this.searchBookings.toLowerCase()))
          )
          }
          return result
        },
    },
    beforeDestroy() {
        let columnState = this.gridApi.getColumnState()
        localStorage.setItem(`columnState-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(columnState))
        localStorage.setItem(`selectedTemplate-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(this.selectedTemplate))
    },
    methods: {
        watchFilters(event) {
          let filter = this.gridApi.getFilterModel();
          this.filtersActive = Object.keys(filter).length > 0
          this.filterModel = filter
        },
        getContextMenuItems(params) {
            let result = [];
            if (!params.node.group) {
                result = [
                  {
                    name: "View Order",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: white; opacity: 0.65; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
                    action: () => {
                      this.viewShipment(params, 'order');
                    },
                  },
                  {
                    name: "View Container",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: white; opacity: 0.65; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
                    action: () => {
                      this.viewShipment(params, 'container');
                    },
                  },
                  'separator',
                  {
                    name: "Create Order",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: #729fd9; margin-bottom: 0px; padding-bottom: 0px">add_circle_outline</span>',
                    action: () => {
                      this.addOrder()
                    },
                  },
                  {
                    name: "Create Order From Booking",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: teal; margin-bottom: 0px; padding-bottom: 0px">add_circle_outline</span>',
                    action: () => {
                      this.openAvailableBookings();
                    },
                  },
                  'separator',
                  'cut',
                  'copy',
                  'copyWithHeaders',
                  'copyWithGroupHeaders',
                  'paste',
                  'separator',
                  'autoSizeAll',
                  'expandAll',
                  'contractAll',
                  'resetColumns',
                  'separator',
                  {
                    name: "Interactive Charts",
                    icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">query_stats</span>',
                  },
                  'chartRange',
                  'export',
                ];
                if(params.column.colDef.filter) {
                    result.unshift({
                    name: "Add to Filter",
                    icon:'<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: white; opacity: 0.65; margin-bottom: 0px; padding-bottom: 0px">filter_list</span>',
                    action: () => {
                        if (params.node && params.node.data) {
                        let multiFilterInstance = this.gridApi.getFilterInstance(params.column.colId);
                        let childFilterInstance = multiFilterInstance.getChildFilterInstance(1);
                        if (childFilterInstance && childFilterInstance.filterNameKey == 'setFilter') {
                                let filterModel = childFilterInstance.getModel();
                                let values = [];
                                if (filterModel && filterModel.values) {
                                    values = filterModel.values;
                                    values.push(params.node.data[params.column.colId]);
                                } else {
                                    values = [params.node.data[params.column.colId]];
                                }
                                childFilterInstance.setModel({ filterType: 'set', values: values });
                                this.gridApi.onFilterChanged();
                            } else {
                                console.error("Child filter instance not found for column:", params.column.colId);
                            }
                        } else {
                            console.error("Filter instance not found for column:", params.column.colId);
                        }
                    },
                  },
                  'separator'
                )
                }
            } else {
                result = [
                {
                    name: "Create Order",
                    action: () => {
                      this.addOrder()
                    },
                  },
                  {
                    name: "Create Order From Booking",
                    action: () => {
                      this.openAvailableBookings();
                    },
                  },
                  'separator',
                  'cut',
                  'copy',
                  'copyWithHeaders',
                  'copyWithGroupHeaders',
                  'paste',
                  'separator',
                  'autoSizeAll',
                  'expandAll',
                  'contractAll',
                  'resetColumns',
                  'separator',
                  'chartRange',
                  'export',
                ];
            }
                return result;
            },
        viewShipment(data, type) {
            let item;
            if(data.node.data) {
                item = data.node.data
                if(item.shipmentReference || item.systemReference) {
                    sessionStorage.setItem('tradingItem', JSON.stringify(item));
                    type ? sessionStorage.setItem('tradingType', type) : sessionStorage.setItem('tradingType', 'shipment'); 
                    if(type === 'container') {
                      sessionStorage.setItem('tradingType', 'order')
                      let obj = {
                        tab: 'overview',
                        shortcut: 'container',
                        id: item.id
                      }
                      sessionStorage.setItem('TradingShortcut', JSON.stringify(obj))
                    } else {
                      sessionStorage.removeItem('TradingShortcut')
                    }
                    this.$router.push({
                    path: "/trading/summary/" + (type === 'shipment' ? item.shipmentReference : item.systemReference),
                  });
                } else {
                    this.$message.error('No Shipment Reference')
                }
            } else {
                console.log('No Data')
            }
        },
        clearFilters() {
          this.gridApi.setFilterModel(null);
        },
        columnsChanged() {
            if(this.selectedTemplate && this.gridApi) {
                let columnState = this.gridApi.getColumnState()
                if(JSON.stringify(columnState) != this.selectedTemplate.columns) {
                    if(this.selectedTemplate.type == 'UserTemplates') {
                        this.canUpdateOwnTemplate = true
                    } else {
                        if(['@dotparse', '@loglive'].some(x => this.$store.state.user.emailAddress.includes(x))) {
                            this.canUpdateOrgTemplate = true
                        }
                    }
                }
            } 
            if(this.gridApi && this.gridLoaded) {
                let columnState = this.gridApi.getColumnState()
                localStorage.setItem(`columnState-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(columnState))
                localStorage.setItem(`pivotMode-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify({pivotMode: this.gridApi.isPivotMode()}))
                console.log('Columns Saved')
            }
        },
        onGridReady(params) {
            this.gridApi = params.api;
            if(localStorage.getItem(`columnState-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`)) {
                let result = this.gridApi.applyColumnState({state: JSON.parse(localStorage.getItem(`columnState-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`)) })
                console.log(result)
                let pivot = localStorage.getItem(`pivotMode-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`)
                let selectedTemplate = JSON.parse(localStorage.getItem(`selectedTemplate-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`))
                this.selectedTemplate = selectedTemplate ?? null
                if(pivot) {
                    pivot = JSON.parse(pivot)
                    this.gridApi.setPivotMode(JSON.parse(pivot.pivotMode))
                } else if(this.selectedTemplate?.pivotMode) {
                    this.gridApi.setPivotMode(this.selectedTemplate.pivotMode)
                }
            }
            this.gridApi.addEventListener("filterChanged", this.watchFilters);
            this.gridLoaded = true

        },
        createChartContainer(chartRef) {
            let chartEl = chartRef.chartElement
            this.showCharts = true
            let theme = 'ag-polychroma-dark'
            let available = this.graphs.filter(x => !x.graph)
            let parentEl;
            if(available.length == 0) {
                this.graphs[3].enabled = true
                this.graphs[3].graph = chartEl
            } else {
                available[0].graph = chartEl
                available[0].enabled = true
            }
            console.log(chartEl)
            this.$nextTick(() => {
                if(available.length == 0) {
                    parentEl = document.querySelector(this.graphs[3].elementId)
                } else {
                parentEl = document.querySelector(available[0].elementId)
                }
            const chartWrapperHTML = `
            <div class="chart-wrapper ${theme}">
                  <div class="chart-wrapper-body"></div>
                  </div>
                  `;
            parentEl.insertAdjacentHTML("beforeend", chartWrapperHTML);
            const eChartWrapper = parentEl.lastElementChild;
            eChartWrapper.querySelector(".chart-wrapper-body").appendChild(chartEl);
        })
        },
        async getRelations() {
          this.loadingOrganisations = true;
          if ( localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id] ) {
            this.organisations = JSON.parse( localStorage[ "relatedOrganisations_" + this.$store.state.currentOrg.id ] );
          }
          this.organisations = await this.$API.getRelationBasic({ });
          let findCurrentOrg = this.organisations.data.find( (x) => x.relatedOrganisationId == this.$store.state.currentOrg.id );
          if (!findCurrentOrg) {
            this.organisations.data.unshift({
              relatedOrganisationId: this.$store.state.currentOrg.id,
              relatedOrganisation: this.$store.state.currentOrg,
            });
          }
          this.loadingOrganisations = false;
        },
        async convertBooking(booking) {
          try {
            this.convertingBooking = true;
          let result = await this.$API.convertBookingToOrder(booking.id);
          this.convertingBooking = false;
          this.confirmBookingConversionModal = false;
          this.$message.success('Booking '+booking.orderNo+' converted to order successfully!')
          this.getBookings();
          this.getContainers();
          } catch(e){
            this.$message.error('Error converting booking to order')
            this.convertingBooking = false
          }
        },
        async getBookings() {
          if (this.bookingTimeout) {
            clearTimeout(this.bookingTimeout);
          }
          if (this.loadingBookings && this.bookingQuery) {
            this.bookingQuery.abort();
          }
          this.loadingBookings = true;
          let params = {
            queryType: "OrderOverview",
            dates: [
              new Date(new Date().setDate(new Date().getDate() - 60))
                .toISOString()
                .substring(0, 10),
              new Date(new Date().setDate(new Date().getDate() + 30))
                .toISOString()
                .substring(0, 10),
            ],
          };
          this.bookingTimeout = setTimeout(async () => {
            this.bookingQuery = new AbortController();
            const signal = this.bookingQuery.signal;
            this.bookingsToConvert = await this.$API.getBookingOverview({
              params: params,
              signal,
            });
            this.loadingBookings = false;
          }, 500);
        },
        openAvailableBookings() {
          this.getBookings();
          this.bookingModal = true;
        },
        confirmBookingToOrder(booking){
          this.selectedBookingToConvert = booking;
          this.confirmBookingConversionModal = true
        },
        addOrder() {
          this.createOrderData = {
            recordType: "ORDER",
            orderStatus: "Planned",
            status: "Draft",
            transportType: "Container",
            movementType: "EXPORT",
            type: "LOGISTICS",
            modeOfTransport: "OCEAN",
            serviceType: "FCL",
            bookingContainerItems: [],
            plannedYear: new Date().getFullYear(),
            plannedWeek: moment().isoWeek(),
          };
          this.orderModal = true;
        },
        changeTemplate(template, index, type) {
            let templateType = type == 'UserTemplates' ? this.userTemplates : this.templates
            this.loading = true
            let find = templateType.find(x => x.id == template.id)
            console.log(find)
            if( find && find.columns) {
                this.gridApi.applyColumnState({state: JSON.parse(find.columns)})
                if(find.pivotMode) {
                    this.gridApi.setPivotMode(find.pivotMode)
                } else {
                    this.gridApi.setPivotMode(false)
                }
                localStorage.setItem(`columnState-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(JSON.parse(find.columns)))
                this.selectedTemplate = find
                localStorage.setItem(`selectedTemplate-trading-${this.$store.state.user.id}-${this.$store.state.currentOrg.id}`, JSON.stringify(find))
                if(find.filters) {
                    this.gridApi.setFilterModel(JSON.parse(find.filters))
                }
            }
            setTimeout(() => {
                this.loading = false
            }, 1000)
        },
        async getTemplates(force) {
            if(force || this.templates.length == 0) {
                this.loadingTemplates = true
                let result = await this.$API.getTradingTemplates();
                if(result) {
                    this.templates = result.organisationTemplates ?? []
                    console.log(this.templates)
                    this.userTemplates = result.userTemplates ?? []
                }
                this.loadingTemplates = false
            }
        },
        getVesselVoyages() {
            let voyages = this.containers.map(x => x.vesselVoyage)
            voyages = Array.from(new Set(voyages))
            this.vesselVoyageSet = voyages
        },
        getShippingLines() {
            let lines = this.containers.map(x => x.shippingLineName)
            lines = Array.from(new Set(lines))
            this.shippingLinesSet = lines
        },
        templateTag(tag, category, prop) {
            let ref = this.templateCreate
            if(prop && prop == 'selected') {
                ref = this.selectedTemplate
            }
            if(typeof ref.allowedParties == 'string') {
                ref.allowedParties = JSON.parse(ref.allowedParties)
            }
            if(typeof ref.tags == 'string') {
                ref.tags = JSON.parse(ref.tags)
            }
            if(!category || category != 'tags') {
                let find = ref.allowedParties.find(x => x == tag)
                if(find) {
                    ref.allowedParties = ref.allowedParties.filter(x => x != tag)
                } else {
                    if(tag == 'Everyone') {
                        ref.allowedParties = ['Everyone']
                    } else {
                        if(ref.allowedParties.find(x => x == 'Everyone')) {
                            ref.allowedParties = ref.allowedParties.filter(x => x != 'Everyone')
                        }
                        ref.allowedParties.push(tag)
                    }
                }
            } else {
                let find = ref.tags.find(x => x == tag)
                if(find) {
                    ref.tags = ref.tags.filter(x => x != tag)
                } else {
                    ref.tags.push(tag)
                }
            }
        },
        async saveTemplate(type) {
            this.loadingTemplateSave = true
            let columns = this.gridApi.getColumnState()
            let filters = this.gridApi.getFilterModel()
            let templateType = type ? type : 'OrgTable'
            let pivotMode = this.gridApi.isPivotMode()
            let payload = {
                title: this.templateCreate.title,
                allowedParties: JSON.stringify(this.templateCreate.allowedParties),
                tags: JSON.stringify(this.templateCreate.tags),
                columns: JSON.stringify(columns),
                filters: JSON.stringify(filters),
                type: templateType,
                pivotMode: pivotMode
            }
            let result = await this.$API.saveTraderTemplate(payload)
            if(result) {
                this.$message.success('Template Saved')
                this.templateCreate.title = ''
                this.templateCreate.allowedParties = ['Everyone']
                this.templateCreate.tags = []
                this.templateCreate.filters = []
                this.getTemplates(true)
            } else {
                this.$message.error('Error Saving Template')
            }
            this.loadingTemplateSave = false
        },
        async updateTemplate(type) {
            this.loadingTemplateSave = true
            let columns = this.gridApi.getColumnState()
            let filters = this.gridApi.getFilterModel()
            let pivotMode = this.gridApi.isPivotMode()
            let templateType = type ? type : 'OrgTemplate'
            let payload = {
                id: this.selectedTemplate.id,
                title: this.selectedTemplate.title,
                allowedParties: JSON.stringify(this.selectedTemplate.allowedParties),
                tags: JSON.stringify(this.selectedTemplate.tags),
                columns: JSON.stringify(columns),
                filters: JSON.stringify(filters),
                type: templateType,
                pivotMode: pivotMode
            }
            let result = await this.$API.updateTraderTemplate(payload)
            if(result) {
                let whichTemplates = templateType == 'OrgTemplate' ? this.templates : this.userTemplates
                if(whichTemplates.length > 0) {
                    let find = whichTemplates.find(x => x.id == this.selectedTemplate.id)
                    if(find) {
                        find.columns = JSON.stringify(payload.columns)
                        find.filters = JSON.stringify(payload.filters)
                    }
                    this.selectedTemplate = find
                    this.canUpdateOwnTemplate = false
                    this.canUpdateOrgTemplate = false
                    this.$message.success('Template Updated')
                    this.getTemplates(true)
                }
            } else {
                console.log('Error Updating Template')
            }
            this.loadingTemplateSave = false
        },
        async deletedSelectedTemplate() {
            this.loadingTemplateSave = true
            let payload = {
                isDeleted: true,
                isActive: false,
                id: this.selectedTemplate.id
            }
            let result = await this.$API.updateTraderTemplate(payload)
            if(result) {
                this.getTemplates(true)
            this.loadingTemplateSave = false
            } else {
                console.log('Error Deleting Template')
            }
        },
        async getContainers() {
            this.loading = true
            let result = await this.$API.getTradingContainers()
            if(result) {
                this.containers = result
                this.loading = false                
                if(!this.selectedTemplate) {
                    this.$nextTick(() => {
                        this.gridApi.autoSizeAllColumns()
                    })
                }
            }
            console.log(JSON.parse(JSON.stringify(this.containers)))
            this.getVesselVoyages()
            this.getShippingLines()
        },
        getColor(status) {
            let state = this.status.find(x => x.name == status)
            if(state) {
                return state.color
            } else {
                return 'grey'
            }
        },
        setCurrentWeek() {
            let currentWeek = moment().isoWeek();
            let currentYear = moment().isoWeekYear();
            this.currentWeek = `${currentYear} - W${currentWeek}`
        },
        showChart() {
            this.showCharts = !this.showCharts
            if(this.showCharts) {
                this.$nextTick(() => {
                    this.initResizeMain()
                    this.initResizeGraphs('.gutter1')
                    this.initResizeGraphs('.gutter2')
                    this.initResizeGraphs('.gutter3')
                })
            }
        },
        handleResizeMain() {
            this.initResizeMain()
        },
        initResizeMain() {
            const resizer = document.querySelector('.gutter');
            if(resizer) {
                const top = document.querySelector('.topSection');
                const bottom = document.querySelector('.bottomSection');
                let x, y, tH, bH, tMin, bMin;
                const mouseDownHandler = (e) => {
                    x = e.clientX;
                    y = e.clientY;
                    let topHeight = window.getComputedStyle(top).height;
                    let bottomHeight = window.getComputedStyle(bottom).height;
                    let bottomMin = window.getComputedStyle(bottom).minHeight;
                    tH = parseInt(topHeight);
                    console.log(tH)
                    bH = parseInt(bottomHeight);
                    tMin = 250;
                    bMin = parseInt(bottomMin);
                    document.addEventListener('mousemove', mouseMoveHandler);
                    document.addEventListener('mouseup', mouseUpHandler);
                }
                const mouseMoveHandler = (e) => {
                    const dy = e.clientY - y;
                    const newTopHeight = tH + dy;
                    const newBottomHeight = bH - dy;
                    let snapHeight = 100;
                    if (newTopHeight < snapHeight) {
                        this.showCharts = false;
                        bottom.style.height = `calc(100vh - 66px)`;
                        document.removeEventListener('mousemove', mouseMoveHandler);
                        document.removeEventListener('mouseup', mouseUpHandler);
                    } else if (newTopHeight < tMin || newBottomHeight < bMin) {
                        return;
                    } else {
                        top.style.height = `${newTopHeight}px`;
                        bottom.style.height = `${newBottomHeight}px`;
                    }
                }
                const mouseUpHandler = () => {
                    document.removeEventListener('mousemove', mouseMoveHandler);
                    document.removeEventListener('mouseup', mouseUpHandler);
                }
                resizer.addEventListener('mousedown', mouseDownHandler);
            }
        },
        handleResizeGraphs(gutter) {
            this.initResizeGraphs(gutter)
        },
        initResizeGraphs(gutter) {
            const resizer = document.querySelector(gutter);
            if(resizer) {
            let g1, g2, g3, g4, topSection;
            topSection = document.querySelector('.topSection');
            this.graphs[0].enabled ? g1 = document.querySelector('.g1') : g1 = null;
            this.graphs[1].enabled ? g2 = document.querySelector('.g2') : g2 = null;
            this.graphs[2].enabled ? g3 = document.querySelector('.g3') : g3 = null;
            this.graphs[3].enabled ? g4 = document.querySelector('.g4') : g4 = null;
            
            let x, y, g1W, g2W, g3W, g4W, totalWidth;
            const mouseDownHandler = (e) => {
                x = e.clientX;
                y = e.clientY;
                let g1Width = g1 ? window.getComputedStyle(g1).width : 0;
                let g2Width = g2 ? window.getComputedStyle(g2).width : 0;
                let g3Width = g3 ? window.getComputedStyle(g3).width : 0;
                let g4Width = g4 ? window.getComputedStyle(g4).width : 0;
                let topWidth = window.getComputedStyle(topSection).width;
                g1W = parseInt(g1Width);
                g2W = parseInt(g2Width);
                g3W = parseInt(g3Width);
                g4W = parseInt(g4Width);
                totalWidth = parseInt(topWidth);
                document.addEventListener('mousemove', mouseMoveHandler);
                document.addEventListener('mouseup', mouseUpHandler);
            }

            const mouseMoveHandler = (e) => {
                const dx = e.clientX - x;
                requestAnimationFrame(() => {
                    if (gutter === '.gutter1' && g1) {
                        let nextElement = g2 ? g2 : g3 ? g3 : g4 ? g4 : null;
                        let nextWidth = g2 ? g2W : g3 ? g3W : g4 ? g4W : null;
                        if(g1W + dx < 250) { return; }
                        else if(nextWidth + (-dx) < 250) { return; }
                        else {
                            g1.style.width = `${Math.max(g1W + dx, 250)}px`;
                            nextElement.style.width = `${nextWidth + (-dx)}px`;
                        }
                    } else if (gutter === '.gutter2' && g2) {
                        let nextElement = g3 ? g3 : g4 ? g4 : null;
                        let nextWidth = g3 ? g3W : g4 ? g4W : null;
                        if(g2W + dx < 250) { return; }
                        else if(nextWidth + (-dx) < 250) { return; }
                        else {
                            g2.style.width = `${Math.max(g2W + dx, 250)}px`;
                            nextElement.style.width = `${nextWidth + (-dx)}px`;
                        }
                    } else if (gutter === '.gutter3' && g3) {
                        let nextElement = g4 ? g4 : null;
                        let nextWidth = g4 ? g4W : null;
                        if(g3W + dx < 250) { return; }
                        else if(nextWidth + (-dx) < 250) { return; }
                        else {
                            g3.style.width = `${Math.max(g3W + dx, 250)}px`;
                            nextElement.style.width = `${nextWidth + (-dx)}px`;
                        }
                    }
                });
            }
            const mouseUpHandler = () => {
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            }
            resizer.addEventListener('mousedown', mouseDownHandler);
            }
        },
    }
}

</script>

<style scoped lang="scss">
// * {
//     --gutters: 0.1rem;
//     --toolBarHeight: 36px;
//     --tabHeight: 30px;
//     --darkLayer: var(--v-greyDarker-base) !important;
// }
@import "~ag-grid-community/styles/ag-grid.css";
@import "../styles/ag-grid-theme-builder.css";

.tabby {


}



.backgroundMain {
    background-color: var(--v-greyRaised-base) !important;
    width: 100%;
    contain: content;
    overflow-y: hidden;
    overflow-x: auto;
    height: calc(100vh - 36px);
}
.dullToHoverText {
    opacity: 0.6;
    transition: opacity 0.2s ease-in-out;
}
.dullToHoverText:hover {
    opacity: 0.9;
}
.text {
    font-size: 0.7rem;
    opacity: 0.7;
}
::v-deep .ag-charts-theme-default {
    --ag-charts-background-color: var(--v-greyRaised-base) !important;
}
.topSection {
    display: flex;
    width: 100%;
    max-width: 100%;
    contain: content;
    overflow: hidden;
}
.graphGrid {
    height: 100%;
    min-width: 100px;
    contain: content;
    display: flex;
}
.g1, .g2, .g3, .g4 {
    background: var(--v-greyDarker-base) !important;
}
.vertHandle {
    rotate: 90deg;
    position: absolute;
    cursor: col-resize !important;
}

.graphState {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px; 
    width: 90px; 
    font-size: 16px;
}

.graphState b {
    z-index: 10
}

.graphState ::v-deep .v-icon {
    position: absolute; 
    z-index:5; 
    opacity: 0.1
}
.gutter1, .gutter2, .gutter3 {
    height: 100%;
    width: 1px !important;
    overflow: visible;
    background: var(--v-greyBase-lighten2);
    cursor: col-resize;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gutter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
    height: 1px !important;
    background: var(--v-greyBase-lighten2);
}
.handle, .vertHandle {
    display: flex;
    overflow: hidden;
    cursor: row-resize;
    width: 38px;
    height: 10px;
    font-size: 6px;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: var(--v-greyBase-lighten2);
    z-index: 999;
}

::v-deep .chart-wrapper {
    height: inherit !important;
    width: inherit !important;
}

::v-deep .chart-wrapper-body {
    height: inherit !important;
}

.handle::after, .vertHandle::after {
  content: 'l l l';
}
.tabsTop {
    width: fit-content;
    height: 30px;
}
.expandPanel {
    background-color: var(--v-greyDarker-base) !important;
    height: 50px;
}

.expandPanel .top, .expandPanel .bottom {
    display: flex;
    align-items: center;
    padding: 4px 4px;
    height: 50%;
}
.expander {
    width: 100%;
    height: 10px;
    background: var(--v-greyDarker-base);
    display: flex;
    align-items: center;
    justify-content: center;
}
.v-tooltip__content {
    padding: 2px 10px;
    font-size: 12px;   
}
.expander:hover {
    cursor: pointer;
    background: var(--v-greyRaised-lighten1) !important;
}
.rightCard {
    visibility: hidden;
    background-color: var(--v-greyRaised-base) !important;
    position: absolute;
    right: calc((350px + var(--toolBarHeight)) * -1);
    max-width: calc(350px + var(--toolBarHeight));
    width: calc(350px + var(--toolBarHeight));
    top: 0;
    height: 100vh;
    box-shadow: -10px 0 10px -5px rgba(0, 0, 0, 0.5);
    padding-right: var(--toolBarHeight);
    z-index: 888;
    transition: right 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.tableHeaderCell:hover, .tableCell:hover {
    background-color: var(--v-greyRaised-lighten1) !important;
}

.tableHeaderCell:hover .sortButton {
    visibility: visible;
}

.sortButton {
    visibility: hidden;
}
.table::-webkit-scrollbar {
  display: none;
}
.resizeSide {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 80%;
    cursor: col-resize;
    background-color: transparent;
    border-radius: 4px;
    z-index: 200;
}
.resizeSide:hover {
    background-color: var(--v-greyBase-lighten2);
}
.tableHeaderCell {
    contain: content;
    cursor: grab;
    overflow-x: hidden;
    align-items: start;
    min-height: 20px !important;
    max-width: 300px !important;
    border-bottom: 1px solid var(--v-greyRaised-lighten2) !important;
}
.menuList .v-list-item{
  font-size: 12px;
  min-height: 30px;
  padding: 0;
}
.menuList ::v-deep .v-icon {
  color: #989898 !important;
}
.rightCard.show {
    right: 0;
    visibility: visible;
}
.v-skeleton-loader.theme--dark ::v-deep .v-skeleton-loader__table-heading {
    background: var(--v-greyRaised-base) !important;
}
.firstRow {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    background-color: var(--v-component-base) !important;
}

.searchBar ::v-deep .v-input__slot {
    height: 32px !important;
    min-height: 32px !important;
}

.rightNav {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--v-component-base) !important;
    width: var(--toolBarHeight);
    min-width: var(--toolBarHeight);
    z-index: 999;
}

.secondRow {
    display: flex;
    width: 100%;
    height: 36px;
    min-height: 36px;
    max-height: 72px;
    padding: 0 2px 0 5px;
    align-items: center;
    background-color: var(--v-greyRaised-lighten1) !important;
}

.innerContent {
  height: 100%;
  width: 100%;
}

.tabsTop ::v-deep .v-tabs .v-tabs-bar, .tabsTop ::v-deep .v-tabs-bar__content, .tabsTop ::v-deep .v-tab{
    background-color: var(--v-greyBase-base) !important;
}


.chipIcon {
    margin-right: 3px;
    font-size: 16px !important;
}
.showHover ::v-deep .pencil {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.showHover:hover ::v-deep .pencil {
    opacity: 1;
}

.listIcon {
    margin-right: 10px;
    font-size: 16px !important;
}

.upIcon {
    rotate: 180deg;
}
.groupBar {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}
.secondaryGroup {
    padding-left: 30px;
}

.tertiaryGroup {
    padding-left: 60px;
}

.tabIcon {
    margin-right: 3px;
    font-size: 20px !important;
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 0.6em;
  width: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  background: var(--v-greyRaised-lighten1) !important
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.denseSelect:not(.fullW) {
    max-width: 150px;
    min-width: 75px;
    width: fit-content !important;
}
.fullW {
    max-width: 300px;
}
.denseSelect ::v-deep div.v-input__control div.v-input__slot {
    font-size: 14px !important;
    max-height: 30px !important;
    min-height: auto !important;
    display: flex !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    align-items: center !important;
    padding-top: 0 !important;
}
.denseSelect ::v-deep div.v-text-field__slot input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.denseSelect ::v-deep div.v-input__slot::before {
    display: none !important;
    border: none !important;
}

.filterWrap {
    background: var(--v-greyDarker-base) !important;
    padding: 5px 5px;
    margin: 5px 0;
    border-radius: 10px;
}

.switcher {
    scale: 0.9;
    margin-top: 2px;
}
.switcher ::v-deep div.v-input--selection-controls__input {
    margin-right: 0;
}

.ag-grid-theme-builder ::v-deep .ag-side-button-button {
    min-height: calc(var(--ag-grid-size) * 7) !important;
    justify-content: flex-start;
    overflow: hidden;
    gap:5px !important;
    white-space: nowrap;
    height: calc(var(--ag-grid-size) * 7) !important;
    max-height: calc(var(--ag-grid-size) * 10) !important;
    transition: height 0.5s ease-in-out, max-height 0.5s ease-in-out;
}

.ag-grid-theme-builder ::v-deep .ag-side-button-button:hover {
    height: 100px !important;
    max-height: calc(var(--ag-grid-size) * 19) !important
}

.ag-grid-theme-builder ::v-deep .ag-side-button-label {
    height: 0px !important;
    transition: height 0.5s ease-in-out;
    display: flex;
    overflow: hidden;
}
.ag-grid-theme-builder ::v-deep .ag-side-button-button:hover .ag-side-button-label {
    height: 80px !important;
}

</style>