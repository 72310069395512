<template>
    <div class="d-flex" style="height: 100%; width: 100%;">
        <div style="position: absolute; left: 4px; top: 1px; z-index: 1;">
            <v-chip-group :loading="loadingSeasons" mandatory active-class="teal--text" class="seasons" >
              <v-menu bottom offset-y v-if="selectedSeason">
                <template v-slot:activator="{on, attrs}">
                    <v-chip v-bind="attrs" v-on="on" small @click="seasonClick()" @contextmenu="seasonContext($event)">
                        {{ selectedSeason.name ?? 'Select Season'}}
                    </v-chip>
                </template>
                <v-card :background-color="'var(--v-greyDarker-base) !important'" style="min-width: 200px; height: auto; border: 1px solid var(--v-greyRaised-lighten2);">
                    <div style="max-height: 200px; height: auto; overflow-y: scroll; background: var(--v-greyDarker-base) !important;">
                        <v-list-item :style="season.id == x.id ? {'background-color': 'var(--v-greyBase-base) !important'} : {'background-color':'var(--v-greyDarker-base) !important'}"
                        v-for="(x, index) in availableSeasons" :key="index" @click="getForecast(x)">
                            <v-list-item-action>
                                <v-icon small>calendar_month</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="pa-0">
                                <v-list-item-title>
                                    {{ x.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <v-divider style="height: 2px; background-color: var(--v-greyRaised-lighten1);"></v-divider>
                    <v-list dense :color="'var(--v-greyDarker-base) !important'" class="py-1 px-0">
                        <v-list-item @click="addSeason()" >
                        <v-list-item-action class="mr-1">
                          <v-icon small color="primary">add_circle_outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-title> Add Season </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="editSeason()" >
                        <v-list-item-action class="mr-1">
                          <v-icon small color="blue">edit</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-title> Edit Season </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="shiftSeasonModal=true">
                        <v-list-item-action class="mr-1">
                          <v-icon small color="teal">refresh</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-title> Shift Season </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="season && season.id" @click="confirmDelete = true">
                        <v-list-item-action class="mr-1">
                          <v-icon small color="red">delete</v-icon>
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-title> Delete Season </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                </v-card>
              </v-menu>
            </v-chip-group>
        </div>
        <div class="firstCol d-flex" :style="sideNav ? {} : {'width': '100% !important'}">
            <v-btn-toggle mandatory multiple :light="!$store.state.darkMode" outlined v-model="toggleView" rounded  class="buttonSwitch">
                <v-btn small outlined class="px-2" style="width: 174px;" color="info">
                    <v-icon small class="mr-1" :color="toggleView.includes(0) ? 'info' : 'primaryText'">attach_money</v-icon>
                    <span style="text-transform: none; color: var(--v-primaryText-base)">Customer Forecasts</span>
                </v-btn>
                <v-btn small outlined class="px-2" style="width: 174px;" :color="'var(--v-tertiary-lighten3)'">
                    <v-icon class="mr-1" small :color="toggleView.includes(1) ? 'var(--v-success-lighten1)' : 'primaryText'">account_balance</v-icon>
                    <span style="text-transform: none; color: var(--v-primaryText-base)">Supplier Forecasts</span>
                </v-btn>
            </v-btn-toggle>
            <div style="height: 100%; width: 100%;" :style="!toggleView.includes(0) ? {'display': 'none'} : ''">
                <div style="background: var(--v-greyRaised-lighten1); height:calc((100vh - 70px) * 0.1); width: 100%; border-right: solid 1px var(--v-greyDarker-base)" ></div>
                <div style="background: var(--v-greyRaised-lighten1); height:40px; width: 100%; border-right: solid 1px var(--v-greyDarker-base)" class="d-flex align-center px-1">
                    <v-chip style="display: grid" class="px-2">
                        <v-autocomplete :menu-props="{offsetY: true}" placeholder="Select Customer" class="mb-1 textFieldSearch" :class="selectedCustomer ? 'hideInput' : ''" hide-details rounded clearable dense :items="customers" v-model="selectedCustomer" :loading="loadingOrganisations" item-text="name" item-value="name">
                            <template v-slot:item="{ item }">
                                <div class="d-flex align-center">
                                    <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                        <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                        <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                    </v-avatar>
                                    <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div class="d-flex align-center">
                                    <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                        <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                        <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                    </v-avatar>
                                    <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-chip style="display: grid" class="px-2">
                        <v-icon style="font-size: 16px !important;" color="grey" class="ml-1">search</v-icon>
                        <v-text-field placeholder="Search" class="mb-1 textFieldSearch searchInput" hide-details rounded clearable dense></v-text-field>
                    </v-chip>
                </div>
                <div style="background: var(--v-greyRaised-lighten1); height:calc((100vh - 70px) * 0.9); width: 100%">
                    <ag-grid-vue style="width: auto; height: 100%;" 
                        :columnDefs="customerHeaders"
                        :rowData="customerItems" 
                        :hideOverlay="loadingForecast"
                        :loading="loadingForecast"
                        :loadingOverlayComponent="loadingOverlayComponent"
                        @first-data-rendered="onFirstDataRendered"
                    />
                </div>
            </div>
            <div style=" height: 100%; width: 100%;" :style="!toggleView.includes(1) ? {'display': 'none'} : ''">
                <div style="background: var(--v-greyRaised-lighten1); height:calc((100vh - 70px) * 0.1); width: 100%; border-left: solid 1px var(--v-greyDarker-base)">
                    
                </div>
                <div style="background: var(--v-greyRaised-lighten1); height:40px; width: 100%; border-left: solid 1px var(--v-greyDarker-base)" class="d-flex align-center px-1">
                    <v-chip style="display: grid" class="px-2">
                        <v-autocomplete :menu-props="{offsetY: true}" placeholder="Select Supplier" class="mb-1 textFieldSearch" :class="selectedSupplier ? 'hideInput' : ''" hide-details rounded clearable dense :items="suppliers" v-model="selectedSupplier" :loading="loadingOrganisations" item-text="name" item-value="name">
                            <template v-slot:item="{ item }">
                                <div class="d-flex align-center">
                                    <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                        <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                        <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                    </v-avatar>
                                    <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div class="d-flex align-center">
                                    <v-avatar size="24" :color="(item.icon || item.logo) ? 'white' : 'var(--v-greyBase-darken1)'" class="mr-2">
                                        <v-img v-if="item.icon || item.logo" :src="item.icon ?? item.logo" contain></v-img>
                                        <span v-else-if="item.name">{{ item.name.toUpperCase().charAt(0) }}</span>
                                    </v-avatar>
                                    <span style="font-size: 12px;">{{ item.alias ?? item.name ?? '' }}</span>
                                </div>
                            </template>
                        </v-autocomplete>
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-chip style="display: grid" class="px-2">
                        <v-icon style="font-size: 16px !important;" color="grey" class="ml-1">search</v-icon>
                        <v-text-field placeholder="Search" class="mb-1 textFieldSearch searchInput" hide-details rounded clearable dense></v-text-field>
                    </v-chip>
                </div>
                <div style="background: var(--v-greyRaised-lighten1); height:calc((100vh - 70px) * 0.9); width: 100%">
                    <ag-grid-vue style="width: auto; height: 100%;" 
                        :columnDefs="supplierHeaders"
                        :rowData="supplierItems"
                        :hideOverlay="loadingForecast"
                        :loading="loadingForecast"
                        :loadingOverlayComponent="loadingOverlayComponent"
                        @first-data-rendered="onFirstDataRendered"
                    />
                </div>
            </div>
        </div>
        <div class="secondCol" v-if="sideNav">
            <div style="width: 100%; height: inherit;">
                <div style="height: auto; width: 100%;" class="d-flex align-center justify-center py-2">
                    <div style="height: 200px; width: 200px; border-radius: 50%; background: var(--v-primary-base);">
                        
                    </div>
                </div>
                <div style="height: auto; width: 100%;" class="d-flex align-center justify-center py-2">
                    <div style="height: 200px; width: 200px; border-radius: 50%; background: var(--v-redPop-base);">

                    </div>
                </div>
            </div>
        </div>
        <!-- Add Season -- ADD SEASON --  -->
        <v-dialog v-model="seasonModal" width="600px" persistent :fullscreen="$vuetify.breakpoint.mobile" >
          <v-card>
            <v-subheader>
              Add Season
              <v-spacer></v-spacer>
              <v-btn icon :disabled="!season.name || !season.start || !season.end" :loading="savingSeason" @click="saveSeason" >
                <v-icon small color="secondary">save</v-icon>
              </v-btn>
              <v-btn icon @click="seasonModal = false" color="redPop">
                <v-icon small>close</v-icon>
              </v-btn> 
            </v-subheader>
        <v-card-text>
          <v-row class="my-2" align="center">
            <v-col cols="12" sm="6">
              <v-text-field
                label="Name*"
                v-model="season.name"
                autofocus
                outlined
                dense
                clearable
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                class="my-2"
                hide-details
                ref="productSelect"
                hide-no-data
                hide-selected
                label="Product*"
                dense
                outlined
                :items="filterProducts"
                clearable
                item-text="name"
                item-value="id"
                :menu-props="{ closeOnContentClick: true }"
                v-model="season.productId"
                :loading="loadingProduct"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.type }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle
                      v-if="data.item.parentProduct"
                      style="font-size: 12px"
                    >
                      Parent: {{ data.item.parentProduct.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list-item>
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left">
                      <v-list-item-action>
                        <v-icon :color="season.start ? 'success' : 'secondary'"
                          >today</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="season.start">
                          {{ season.start }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle>
                          Start Date
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker
                    v-model="season.start"
                    :max="season.end"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-list-item>
            </v-col>
            <v-col cols="12" sm="6">
              <v-list-item>
                <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on }">
                    <v-list-item v-on="on" class="text-left">
                      <v-list-item-action>
                        <v-icon :color="season.end ? 'success' : 'secondary'"
                          >today</v-icon
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-if="season.end">
                          {{ season.end }}
                        </v-list-item-title>
                        <v-list-item-title v-else> - </v-list-item-title>
                        <v-list-item-subtitle> End Date </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-date-picker
                    v-model="season.end"
                    :min="season.start"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue";
import {AgChartsVue} from "ag-charts-vue"
import moment from "moment"
import LoadingOverlay from "../DynamicTables/AgGrid/LoadingOverlay.vue"

/* eslint-disable vue/no-unused-components */

/* eslint-disable vue/no-unused-components */
export default {
    props: [],
    components: {
        AgGridVue,
        AgChartsVue,
        LoadingOverlay
    },
    data: () => ({
        customers: null,
        suppliers: null,
        sideNav: false,
        loadingOverlayComponent: 'LoadingOverlay',
        organisations: {
            data: [],
            total: 0,
        },
        defaultColumn: {
            sortable: true,
            filter: 'agMultiColumnFilter',
            enableRowGroup: false,
            cellClass: 'd-flex align-center',
            enablePivot: true,
        },
        customerHeaders: [
            // {
            //     headerName: "Features",
            //     field: "features",
            //     getQuickFilterText: params => {
            //         return ''
            //     },
            //     filter: false,
            //     cellClass: 'd-flex align-center justify-center',
            //     cellRenderer: 'CellRenders',
            //     cellRendererParams: (params) => {
            //         return {
            //             type: 'features',
            //             propData: params.data?.temporaryProducts ?? null
            //         }
            //     }
            // },
            {
                headerName: "Customer",
                field: "consigneeProfile.consignee.alias",
            },
            {
                headerName: "Product",
                field: "product.code",
            },
            {
                headerName: "Week",
                field: "plannedWeekName",
            },
            {
                headerName: "Containers",
                field: "containerQuantity",
            },
            {
                headerName: "Pallets",
                field: "palletQuantity",
            },
            {
                headerName: "Units",
                field: "unitQuantity",
            },
            {
                headerName: "Destination",
                field: "destinationCountry.name",
            },
        ],
        supplierHeaders: [
            {
                headerName: "Supplier",
                field: "Supplier", 
            },
            {
                headerName: "Product",
                field: "product.code",
            },
            {
                headerName: "Week",
                field: "plannedWeekName",
            },
            {
                headerName: "Containers",
                field: "containerQuantity",
            },
            {
                headerName: "Pallets",
                field: "palletQuantity",
            },
            {
                headerName: "Units",
                field: "unitQuantity",
            },
            {
                headerName: "Destination",
                field: "destinationCountry.name",
            },
        ],
        toggleView: [0, 1],
        loadingSeasons: false,
        loadingOrganisations: false,
        availableSeasons: [],
        selectedSeason: null,
        loadingForecast: false,
        searchTimeout: undefined,
        graphKey: 0,
        showMenu: false,
        season: {},
        seasonModal: false,
        x: null,
        y: null,
        searchCustomers: "",
        filters: { searchCustomers: ""},
        existingCustomerQuery: undefined,
        existingSupplierQuery: undefined,
        selectedCustomer: null,
        selectedSupplier: null,
        customerItems: [],
        supplierItems: [],
        topCustomerCountries: [],
        topCustomerVarieties: [],
        topSuppliesCountries: [],
        topSupplierVarieties: [],

    }),
    async mounted() {
        this.getSeasons()
        this.getRelations()
    },
    watch: {
        searchCustomers: {
            immediate: true,
            handler(val) {
                if(this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                }
                this.searchTimeout = setTimeout(() => {
                    this.searchTimeout = undefined
                    this.filters.searchCustomers = val
                }, 500)
            }
        },
    },
    computed: {
        filteredCustomers() {
            if(this.customers.length === 0) {
                return []
            }
            let customers = this.customers
            if(this.filters.searchCustomers) {
                customers = customers.filter(customer => {
                    return customer.organisation.name.toLowerCase().includes(this.filters.searchCustomers.toLowerCase())
                })
            }
            return customers
        }
    },
    methods: {
        seasonContext(e) {
            this.season = this.selectedSeason
            e.preventDefault();
        },
        seasonClick() {
            this.season = this.selectedSeason
        },
        addSeason() {
          this.season = {
            year: new Date().getFullYear(),
            name: "",
            start: moment().startOf("year").format("YYYY-MM-DD"),
            end: moment().endOf("year").format("YYYY-MM-DD"),
          };
          this.seasonModal = true;
        },
        onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
        },
        async getSeasons() {
          this.loadingSeasons = true;
          this.availableSeasons = await this.$API.getSeasons();
          if (this.availableSeasons.length > 0 && !this.selectedSeason) {
            this.selectedSeason = this.availableSeasons[0];
            console.log(this.selectedSeason)
            this.getForecast(this.selectedSeason)
          }
          this.loadingSeasons = false;
        },
        async getRelations() {
            this.loadingOrganisations = true;
            if ( localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id] ) {
                this.organisations = JSON.parse( localStorage[ "relatedOrganisations_" + this.$store.state.currentOrg.id ]);
            }
            this.organisations = await this.$API.getRelationBasic({});
            let customers = this.organisations.data.filter(x => x.isCustomer)
            for(let i = 0; i < customers.length; i++) {
                let {alias, relatedOrganisation, relationshipTeams, ...otherInfo} = customers[i]
                customers[i] = {
                  organisationRelationship: {...otherInfo, alias: alias},
                  relatedTeams: relationshipTeams,
                  ...relatedOrganisation,
                  alias: alias ?? relatedOrganisation.alias ?? null
                }
            }
            this.customers = customers
            console.log(this.customers)
            let suppliers = this.organisations.data.filter(x => x.isSupplier)
            for(let i = 0; i < suppliers.length; i++) {
                let {alias, relatedOrganisation, relationshipTeams, ...otherInfo} = suppliers[i]
                suppliers[i] = {
                    organisationRelationship: {...otherInfo, alias: alias},
                    relatedTeams: relationshipTeams,
                    ...relatedOrganisation,
                    alias: alias ?? relatedOrganisation.alias ?? null
                }
            }
            this.suppliers = suppliers
            localStorage.setItem( "relatedOrganisations_" + this.$store.state.currentOrg.id, JSON.stringify(this.organisations) );
            this.loadingOrganisations = false;
        },
        async getForecast(season = null) {
            if (season) {
              this.selectedSeason = season;
            }
            if (this.selectedSeason) {
                if (this.loadingForecast && this.existingCustomerQuery) {
                    this.existingCustomerQuery.abort()
                }
                if(this.loadingForecast && this.existingSupplierQuery) {
                    this.existingSupplierQuery.abort()
                }
                this.loadingForecast = true;
                this.existingCustomerQuery = new AbortController();
                this.existingSupplierQuery = new AbortController();
                const customerSignal = this.existingCustomerQuery.signal
                const supplierSignal = this.existingSupplierQuery.signal
                let customerParams = {
                    seasonId: this.selectedSeason.id,
                    forecastType: 'CUSTOMER',
                  }
                let supplierParams = {
                    seasonId: this.selectedSeason.id,
                    forecastType: 'SUPPLIER',
                }
                let [customerResult, supplierResult] = await Promise.allSettled([
                    this.$API.getForecast({ params: customerParams, signal:customerSignal}),
                    this.$API.getForecast({ params: supplierParams, signal:supplierSignal})
                ])
                if (customerResult.status == "rejected") {
                    console.error(customerResult.reason);
                    this.loadingForecast = false;
                    this.$message.error("Failed to load customer forecast");
                    return;
                }
                if (supplierResult.status == "rejected") {
                    console.error(supplierResult.reason);
                    this.loadingForecast = false;
                    this.$message.error("Failed to load supplier forecast");
                    return;
                }
                this.customerItems = customerResult.value.filter(x => x.type != 'subheader');
                this.supplierItems = supplierResult.value.filter(x => x.type != 'subheader');
                this.graphKey++;
                this.loadingForecast = false;
                if(this.customerItems?.length > 0) {
                    let uniqueCountriesCustomer = [...new Set( this.customerItems .filter((x) => x.destinationCountryId) .map((x) => x.destinationCountryId) )];
                    let customerCountries = [];
                    let totalCustomerUnits = this.customerItems.filter(x=>x.id && x.unitQuantity).reduce( (a, b) => a + b.unitQuantity, 0);
                    for (let i = 0; i < uniqueCountriesCustomer.length; i++) {
                        let country = uniqueCountriesCustomer[i];
                        let countryData = this.customerItems.filter( (x) => x.destinationCountryId == country );
                        let countryName = countryData[0].destinationCountry.name;
                        let containers = countryData.reduce( (a, b) => a + b.containerQuantity, 0 );
                        let pallets = countryData.reduce((a, b) => a + b.palletQuantity, 0);
                        let units = countryData.reduce((a, b) => a + b.unitQuantity, 0);
                        customerCountries.push({
                          iso2: countryData[0].destinationCountry.iso2,
                          name: countryName,
                          containers: containers,
                          pallets: pallets,
                          units: units,
                          percentage: Math.round((units / totalCustomerUnits) * 100),
                        });
                    }
                    this.topCustomerCountries = customerCountries.sort((a, b) => (a.units > b.units ? -1 : b.units > a.units ? 1 : 0)).slice(0, 5);
                    let allCustomerVariants = [].concat.apply([], this.customerItems.filter(x => x.orderCargoItems).map(x=> x.orderCargoItems))
                    let uniqueCustomerVariants = [...new Set(allCustomerVariants.map(x => x.varietyProductId))].filter(Boolean)
                    let customerVarieties = []
                    for(let i = 0; i < uniqueCustomerVariants.length; i++){
                        let variant = uniqueCustomerVariants[i]
                        let variantData = allCustomerVariants.filter(x => x.varietyProductId == variant)
                        let variantName = variantData[0].varietyProduct.name
                        let product = this.customerItems.find(x=>x.id == variantData[0].forecastId)
                        let units = variantData.reduce((a,b) => a + b.quantity, 0)
                        customerVarieties.push({
                          product: product.product.name,
                          name: variantName,
                          units: units,
                          percentage: Math.round((units / totalCustomerUnits) * 100),
                        })
                    }
                    this.topCustomerVarieties = customerVarieties.sort((a,b) => (a.units > b.units) ? -1 : (b.units > a.units) ? 1 : 0).slice(0,5)
                }
                if(this.supplierItems?.length > 0) {
                    let uniqueCountriesSupplier = [...new Set( this.supplierItems .filter((x) => x.destinationCountryId) .map((x) => x.destinationCountryId) )];
                    let supplierCountries = [];
                    let totalSupplierUnits = this.supplierItems.filter(x=>x.id && x.unitQuantity).reduce( (a, b) => a + b.unitQuantity, 0);
                    for (let i = 0; i < uniqueCountriesSupplier.length; i++) {
                        let country = uniqueCountriesSupplier[i];
                        let countryData = this.supplierItems.filter( (x) => x.destinationCountryId == country );
                        let countryName = countryData[0].destinationCountry.name;
                        let containers = countryData.reduce( (a, b) => a + b.containerQuantity, 0 );
                        let pallets = countryData.reduce((a, b) => a + b.palletQuantity, 0);
                        let units = countryData.reduce((a, b) => a + b.unitQuantity, 0);
                        supplierCountries.push({
                          iso2: countryData[0].destinationCountry.iso2,
                          name: countryName,
                          containers: containers,
                          pallets: pallets,
                          units: units,
                          percentage: Math.round((units / totalSupplierUnits) * 100),
                        });
                    }
                    this.topSuppliesCountries = supplierCountries.sort((a, b) => (a.units > b.units ? -1 : b.units > a.units ? 1 : 0)).slice(0, 5);
                    let allSupplierVariants = [].concat.apply([], this.supplierItems.filter(x => x.orderCargoItems).map(x=> x.orderCargoItems))
                    let uniqueSupplierVariants = [...new Set(allSupplierVariants.map(x => x.varietyProductId))].filter(Boolean)
                    let supplierVarieties = []
                    for(let i = 0; i < uniqueSupplierVariants.length; i++){
                        let variant = uniqueSupplierVariants[i]
                        let variantData = allSupplierVariants.filter(x => x.varietyProductId == variant)
                        let variantName = variantData[0].varietyProduct.name
                        let product = this.supplierItems.find(x=>x.id == variantData[0].forecastId)
                        let units = variantData.reduce((a,b) => a + b.quantity, 0)
                        supplierVarieties.push({
                          product: product.product.name,
                          name: variantName,
                          units: units,
                          percentage: Math.round((units / totalSupplierUnits) * 100),
                        })
                    }
                    this.topSupplierVarieties = supplierVarieties.sort((a,b) => (a.units > b.units) ? -1 : (b.units > a.units) ? 1 : 0).slice(0,5)
                }
                // this.calculateFilteredResults();
            }
        },

    }
}
</script>

<style scoped>


.firstCol {
    height: 100%;
    width: calc(100vw * 0.85);
    max-width: 100vw;
    background: var(--v-greyRaised-lighten2) !important;
    contain: content;
}

.secondCol {
    height: 100%;
    min-width: 0px;
    width: calc(100vw * 0.15);
    background: var(--v-greyRaised-lighten2) !important;
}
.listItemLeft {
    min-height: 38px;
    height: 38px;
    padding: 0 0px !important;
}

.v-list-item {
    min-height: 30px;
    height: 30px;
    padding: 0px 0px !important;
}
.v-list-item__avatar {
    margin: 0 0px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
}

.v-list-item__avatar ::v-deep .v-icon::after {
    height: auto !important;
}
.v-list-item__action ::v-deep .v-icon::after {
    height: auto !important;
}
.v-list-item__action {
    margin: 0 0px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.v-list-item__title {
    font-size: 0.75rem !important;
    line-height: 1.4 !important;
}

.v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}

.textFieldSearch ::v-deep div.v-input__control div.v-input__slot {
    font-size: 0.75rem !important;
    max-height: 24px !important;
    min-height: auto !important;
    padding: 0 4px;
}

.textFieldSearch ::v-deep button.v-icon.v-icon--link {
    font-size: 20px;
}

.textFieldSearch.hideInput:active ::v-deep input {
    width: 100%;
    max-width: 100%;
}
.textFieldSearch.hideInput ::v-deep input {
    width: 1px;
    max-width: 1px;
}

.buttonSwitch {
    margin-left: 1px;
    position: fixed;
    left: 50%;
    transform: translate(-50%);
}
.seasons ::v-deep .v-slide-group__content  {
    padding: 0;
}
</style>