<template>
    <div>
        <div v-if="field == 'organisation' && propData">
            <v-avatar size="20" :color="propData.logo ? 'white' : 'secondary' " left>
              <v-img v-if="propData.logo" :src="propData.logo" contain></v-img>
              <h3 v-else style="color: white">
                {{ propData.name.charAt(0) }}
              </h3>
            </v-avatar>
            <span class="ml-2">{{ propData?.alias ? propData.alias : propData?.name ? propData.name : ' - ' }}</span>
        </div>
        <div v-else-if="field == 'fileStatus'">
          <v-tooltip top open-delay="2000">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="!value || !['Ready', 'Sent'].includes(value) ? '#9e9e9e3b' : value == 'Ready' ? 'tertiary' : value == 'Sent' ? 'primary' : '#9e9e9e3b'">
              {{ !value ?  'folder_off' : value == 'Ready' ? 'forward_to_inbox' : value == 'Sent' ? 'mark_email_read' : 'folder_off'}}
            </v-icon>
            </template>
            {{ value == 'Ready' ? 'Ready to Send' : value == 'Sent' ? 'Sent' : 'Not Ready' }}
          </v-tooltip>
        </div>
        <div v-else-if="field == 'locode'">
            <v-avatar size="20" left v-if="value">
              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${value.substring(0, 2).toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ value }}
        </div>
        <div v-else-if="field == 'shippingLine' && propData" >
          <v-avatar size="20" left v-if="propData.logo || propData.icon" color="white">
              <v-img contain :src="propData.logo ?? propData.icon"
              ></v-img>
            </v-avatar>
            {{ propData.alias ?? propData.name }}
        </div>
        <div v-else-if="field == 'features' && propData">
            <v-tooltip top v-if="propData.some((product) => product.product === 'Genset')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#D6D46D" >
                  <v-icon small>bolt</v-icon>
                </v-chip>
              </template>
              <span>Genset Required</span>
            </v-tooltip>
            <v-tooltip
              top v-if="propData.some((product) => product.product === 'Fumigation')" open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#F1DBBF" >
                  <v-icon small>cleaning_services</v-icon>
                </v-chip>
              </template>
              <span>Fumigations Required</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Void Plugs')" open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#e05b0d">
                  <v-icon small>power</v-icon>
                </v-chip>
              </template>
              <span>Void Plugs</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Blast Freezing')" open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#13a7d4">
                  <v-icon small>ac_unit</v-icon>
                </v-chip>
              </template>
              <span>Blast Freezing</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Monitoring Service')" open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#99B080">
                  <v-icon small>desktop_windows</v-icon>
                </v-chip>
              </template>
              <span> {{ propData.find((product) => product.product === "Monitoring Service").variation + " " + "Service" + " " + "Required"}}
              </span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'ContainerLiners')" open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#EEF296">
                  <v-icon small>settings_overscan</v-icon>
                </v-chip>
              </template>
              <span>
                {{ "Container Liners" + " " + "Required" }}
              </span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'SmartFresh')" open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#FFBFFF">
                  <v-icon small>smart_screen</v-icon>
                </v-chip>
              </template>
              <span>
                {{ "Smart Fresh" + " " + "Required" }}
              </span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'SteriInsurance')" open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#EEF296">
                  <v-icon small>emergency</v-icon>
                </v-chip>
              </template>
              <span>
                {{ "Steri Insurance" + " " + "Required" }}
              </span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'TelemPlus')" open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#18e00d">
                  <v-icon small>router</v-icon>
                </v-chip>
              </template>
              <span>
                Devices Required:
                <template v-if="propData.some((product) => product.product === 'TelemPlus' )" open-delay="1000">
                  <ul>
                    <li :key="index" v-for="([variation, count], index) in Object.entries(
                      propData.filter((product) =>product.product === 'TelemPlus').reduce((acc, product) => {
                      if (!acc[product.variation]) {acc[product.variation] = 0;} 
                      acc[product.variation] += product.count;return acc;}, {}))" >
                      {{ count }}X{{ variation }}
                    </li>
                  </ul>
                </template>
              </span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Otflow')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#6A9C89">
                  <v-icon small>air</v-icon>
                </v-chip>
              </template>
              <span>Otflow Required</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Zoono')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#5b93c7">
                  <v-icon small>sanitizer</v-icon>
                </v-chip>
              </template>
              <span>Zoono Required</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Dunnage Bags')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#F1DBBF">
                  <v-icon small>shopping_bag</v-icon>
                  </v-chip>
              </template>
              <span>Dunnage Bags Required</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Late Arrival')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#BB2525">
                  <v-icon small>watch_later</v-icon>
                  </v-chip>
              </template>
              <span>
                Late Arrival Required :
                {{propData.find((product) => product.product === "Late Arrival").variation}}
              </span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Latches')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#508D69">
                  <v-icon small>pivot_table_chart</v-icon>
                  </v-chip>
              </template>
              <span>Latches Required</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Ratches')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#EA906C" >
                  <v-icon small>settings_input_component</v-icon>
                </v-chip>
              </template>
              <span>Ratches Required</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Stabling bars')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#5F6F52">
                  <v-icon small>video_stable</v-icon>
                  </v-chip>
              </template>
              <span>Stabling bars Required</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Kraft paper')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#C5E898">
                  <v-icon small>note</v-icon>
                  </v-chip>
              </template>
              <span>Kraft paper Required</span>
            </v-tooltip>
            <v-tooltip top v-if="propData.some((product) => product.product === 'Absorb Bags')"  open-delay="1000">
              <template v-slot:activator="{ on }">
                <v-chip outlined x-small class="px-0 mx-1" v-on="on" color="#DCBFFF">
                  <v-icon small>backpack</v-icon>
                  </v-chip>
              </template>
              <span>Absorb Bags Required</span>
            </v-tooltip>
        </div>
        <div v-else-if="field == 'stockAllocationStatus' && item" @click="test()">
          <v-tooltip top open-delay="2000">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" v-if="!item.totalPallets && item.allocatedPallets" color="danger">error_outline</v-icon>
              <v-progress-circular
                v-else-if="item.totalPallets && item.allocatedPallets"
                v-on="on"
                :value="item.allocatedProgress"
                size="20"
                :color="
                  item.totalPallets == 0
                    ? 'grey'
                    : item.allocatedProgress < 20
                    ? 'red'
                    : item.allocatedProgress < 50
                    ? 'orange'
                    : item.allocatedProgress < 100
                    ? 'blue'
                    : 'success'
                "
              >
              </v-progress-circular>
            </template>
            <span v-if="!item.totalPallets && item.allocatedPallets">
              {{ item.allocatedPallets }} Pallet{{ item.allocatedPallets > 1 ? 's have' : 'has' }} been Allocated, but no Total Pallets has been set.
            </span>
            <span v-else-if="item.totalPallets && item.allocatedPallets">
              Allocated: {{ item.allocatedPallets }}/{{
                item.totalPallets
              }}
              </span>
          </v-tooltip>
        </div>
        <div v-else-if="field == 'product' && item?.products">
            <v-tooltip right open-delay="2000">
              <template v-slot:activator="{ on }">
                <div v-on="on" style="height: 100%; width: 100%;">
                    <v-chip v-for="(product, index) in item.products" :key="index" class="px-2" x-small>
                      {{ product ? product.code ?? product.name : 'N/A' }}
                    </v-chip>
                </div>
              </template>
                <li v-for="(product, index) in item.products" :key="index" style="font-size: 11px">
                  {{product.name ? $Format.capitalizeFirstLetter(product.name): ""}}
                </li>
            </v-tooltip>
        </div>
        <div v-else-if="field == 'orderStatus'">
          <v-chip :color="orderStatus.find(x => x.name == value)?.color ?? 'grey'" x-small class="px-2">
            {{ value }}
          </v-chip>
        </div>
        <div v-else-if="field == 'state'">
          <v-chip :color="state.find(x => x.name == value)?.color ?? 'grey'" x-small class="px-2">
            {{ value }}
          </v-chip>
        </div>
        <div v-else-if="field && !value && !propData">
            {{ '' }}
        </div>
        <div v-else-if="field === null">
            {{ '' }}
        </div>
        <div v-else-if="field && value">
            {{ value }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orderStatus: [
              { name: 'Planned', color: '#9d5aa8' },
              { name: 'Awaiting Departure', color: '#dc9f44' },
              { name: 'In Transit', color: '#417eaf' },
              { name: 'Arrived', color: '#4c8d4f' },
              { name: 'Cancelled', color: '#cd584f' }
            ],
            state: [
              { name: "Draft", icon: "edit_note", color: "#F57C00" },
              { name: "Pending", icon: "pending", color: "#009688" },
              { name: "Requested", icon: "verified", color: "#2196F3" },
              { name: "Confirmed", icon: "check_circle_outline", color: "#007d5c" },
              { name: "Ghost", icon: "help", color: "#607D8B" },
              { name: "Cancelled", icon: "cancel", color: "#F44336" },
              { name: "Rejected", icon: "warning", color: "#F44336" },
            ],
            value: "",
            field: "",
            propData: null,
            item: null,
        }
    },
    beforeMount() {
        this.value = this.params.value;
        this.item = this.params.data;
        if(this.params.type) {
            this.field = this.params.type
        } else {
            this.field = this.params.colDef.field;
        }
        if(this.params.propData) {
            this.propData = this.params.propData;
        }
    },
    methods: {
        test() {
          this.params.openAvailableBookings()
        },
        onClick() {
            alert("The age is " + this.value);
        }
    }
}
</script>